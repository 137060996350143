#card-form {
    width: 30vw;
    align-self: baseline;
    box-shadow: 0px 0px 0px 0.5px rgba(50, 50, 93, 0.1),
        0px 2px 5px 0px rgba(50, 50, 93, 0.1), 0px 1px 1.5px 0px rgba(0, 0, 0, 0.07);
    border-radius: 7px;
    padding: 40px;
}
#card-message{
    color: red;
}
#card-form-input-field{
    margin: 12px;
}
#renter-information{
    display: flex;
    flex-direction: column;}
#renter-information .renter-information-input{
    margin: 12px 0;
}
#checkout-page-container{
    display: flex;
    justify-content: space-evenly;
    margin-bottom: 4rem;
}
#checkout-information{
    align-self: baseline;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    width: 30vw;
    box-shadow: 0px 0px 0px 0.5px rgba(50, 50, 93, 0.1),
        0px 2px 5px 0px rgba(50, 50, 93, 0.1), 0px 1px 1.5px 0px rgba(0, 0, 0, 0.07);
    border-radius: 7px;
    padding: 40px;
}

#payment-page-header{
    text-align: center;
    margin-top: 5vh;
}

@media (max-width: 1200px) {    
    #checkout-page-container{
        display: flex;
        flex-direction: column;
    }
    #checkout-information{
        align-self: center;
        width: 80vw;
        margin-bottom: 5vh;
    }
    #card-form{
        width: 80vw;
    }
}


#payment-message {
    color: rgb(105, 115, 134);
    font-size: 16px;
    line-height: 20px;
    padding-top: 12px;
    text-align: center;
}

#payment-button{
    background: #2f50a6;
    font-family: Arial, sans-serif;
    color: #ffffff;
    border-radius: 4px;
    border: 0;
    padding: 12px 16px;
    font-size: 16px;
    font-weight: 600;
    cursor: pointer;
    display: block;
    transition: all 0.2s ease;
    box-shadow: 0px 4px 5.5px 0px rgba(0, 0, 0, 0.07);
    width: 100%;
}

button:hover {
    filter: contrast(115%);
}

button:disabled {
    opacity: 0.5;
    cursor: default;
}


.add-ons-item{
    display: flex;
    justify-content: space-between;
    border: 2px solid #b1acad42;
    padding: 8px;
    align-items: flex-start;
    border-radius: 7px;
    margin: 8px 0;
}

.add-ons-item:hover{
    cursor: pointer;
    border: 2px solid #1a76d268;
}

.selected-add-on{
    border: 2px solid #1a76d2ef;
}