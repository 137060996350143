.welcomeBox{
    border: 2px solid black;
    align-items: center;
    display: flex;
    flex-direction: column;
    line-height: 1px;
    width: 50%
}

.idBox{
    display: inline-block;

}


.adminTableContainer{
    margin-top: 50px;
    border: 2px solid black;
    
}
.adminTableTitle{
    align-items: center;
    line-height: 1px;
    height: 50px;
    margin:0px;
   background-color: rgb(160, 187, 206);
   display: flex;
   justify-content: center;
   border: 2px solid black;

  
}


.addClientButton{
    width: 200px;
    height: 100px;
    border: 4px solid black;
    padding: 2.5px;
    font-size: 30px;
    font-variant: small-caps;
    font-weight: bolder;
    background-color: rgb(97, 157, 206);


}

.addClientButton:hover{
    background-color: rgb(150, 164, 106);
    border: 7px solid rgb(7, 46, 78);
    color: rgb(32, 25, 127)
}

.adminHeader{
    display: flex;
    align-items: center;
    padding-left: 10px;
    justify-content: space-around;
    margin-top: 10px;
    text-align: center;
}

#booking-toolbar{
    margin: 1.5rem 0;
    display: flex;
    justify-content: center;
}