.nav {
  /* flex box layout */
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 7px solid black;
  height: 100px;

  background-color: #272aad;

}

.nav a {
  text-decoration: none;
  position: relative;
}

.nav-title {
  font-size: 24px;
  font-weight: 700;
  color: #f2f2f2;
 padding-left: 40px;
  width: 200px;
  height: 100px;

  display: flex;
  align-items: center;
  margin: auto;
  /* background-color: #071f70; */
}

.navLink {
  display: inline-block;
  color: black;
  text-align: center;
  padding: 24px 10px;
  text-decoration: none;
  font-size: 15px;
  width: fit-content;


  /* these last two make the logout button in
  the nav bar look like another link */
  border: none;
  cursor: pointer;
  outline: 0;
}

.navLink:hover {
  background-color: lightgrey;
  
}


/* The container <div> - needed to position the dropdown content */
.dropdown {
  position: relative;
  display: inline-block;
}
.a.dropdown{
  background: red;
}
/* Dropdown Content (Hidden by Default) */
.dropdown-content {
  display: none;
  position: absolute;
  background-color: #f9f9f9;
  /* min-width: 200px; */
  /* box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2); */
  z-index: 1;
}

/* Links inside the dropdown */
.dropdown-content a {
  color: black;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
  width: 150px;
  border: 2px black solid;
  background-color: #becaf4;
}

/* Change color of dropdown links on hover */
.dropdown-content a:hover {background-color: #aaccf3}

/* Show the dropdown menu on hover */
.dropdown:hover .dropdown-content {
  display: block;
}

/* Change the background color of the dropdown button when the dropdown content is shown */
.dropdown:hover .dropbtn {
  background-color: #62729f;
  border: 4px solid rgb(65, 179, 179);
}

.dropbtn {
  background-color: #5668a3;
  color: white;
  padding: 16px;
  font-size: 15px;
  border: 2px solid black;
  cursor: pointer;
  margin: 10px;
}

#logo {
  width: 150px;
  padding: 10px;
  padding-left: 0px;
  cursor:pointer
}

.userDropdown {
  display: inline-block;
  color: black;
  text-align: center;
  padding-top: 2px;
  padding-bottom: 2px;
  padding-left: 8px;
  padding-right: 8px;
  text-decoration: none;
  font-size: 15px;
  width: fit-content;

  border: none;
  cursor: pointer;
  outline: 0;
}
