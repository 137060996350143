.form-group{
    display: flex;
    flex-direction: column;
    margin: auto;
    align-items: center;
    margin-top: 20px;
}



.photoSubmitButton{
    display: flex;
    margin: auto;
    margin-top: 20px;
}

.cardBox{
    margin: auto;
    margin-top: 20px;
}