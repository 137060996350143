



/*
  Template Name: NordEx - Premium App Landing Pages Pack
  Theme URL: https://themeforest.net/user/dsathemes
  Description: NordEx - Premium App Landing Pages Pack
  Author: DSAThemes
  Author URL: https://themeforest.net/user/dsathemes
  Version: 1.0.0
  Website: www.dsathemes.com
  Tags: Responsive, HTML5 template, DSAThemes, Mobile, Application, One Page, Landing, Product, Mobile App
*/




/* ==========================================================================
  01. GENERAL & BASIC STYLES
  =========================================================================== */

html {
  -webkit-font-smoothing: antialiased;
  font-size: 16px;
} 

/* body {
  font-family: "Google Sans", Helvetica, Arial, sans-serif;
  color: #998da0;
  font-weight: 300;
} */
/* p {
  font-family: "Google Sans", Helvetica, Arial, sans-serif;
  color: #998da0;
  font-weight: 300;
} */

#loader-wrapper {
  display: none;
}

@media only screen and (max-width: 600px) {
  .img-ipad {
    max-width: 100%;
    height: 100%;
  }
}

/*------------------------------------------------------------------*/
/*  IE10 in Windows 8 and Windows Phone 8 Bug fix
/*-----------------------------------------------------------------*/

@-webkit-viewport { width: device-width; }
@-moz-viewport { width: device-width; }
@-ms-viewport { width: device-width; }
@-o-viewport { width: device-width; }
@viewport { width: device-width; }

/*------------------------------------------*/
/*  PAGE CONTENT
/*------------------------------------------*/

#page { overflow: hidden; }

/*------------------------------------------*/
/*  SPACING & INDENTS
/*------------------------------------------*/

.wide-100 { padding-top: 100px; padding-bottom: 100px; }
.wide-90 { padding-top: 100px; padding-bottom: 90px; }
.wide-80 { padding-top: 100px; padding-bottom: 80px; }
.wide-70 { padding-top: 100px; padding-bottom: 70px; }
.wide-60 { padding-top: 100px; padding-bottom: 60px; }
.wide-50 { padding-top: 100px; padding-bottom: 50px; }
.wide-40 { padding-top: 100px; padding-bottom: 40px; }
.wide-30 { padding-top: 100px; padding-bottom: 30px; }
.wide-20 { padding-top: 100px; padding-bottom: 20px; }

/*------------------------------------------*/
/*  Margin Top
/*------------------------------------------*/

.mt-100 { margin-top: 100px; }
.mt-95 { margin-top: 95px; }
.mt-90 { margin-top: 90px; }
.mt-85 { margin-top: 85px; }
.mt-80 { margin-top: 80px; }
.mt-75 { margin-top: 75px; }
.mt-70 { margin-top: 70px; }
.mt-65 { margin-top: 65px; }
.mt-60 { margin-top: 60px; }
.mt-55 { margin-top: 55px; }
.mt-50 { margin-top: 50px; }
.mt-45 { margin-top: 45px; }
.mt-40 { margin-top: 40px; }
.mt-35 { margin-top: 35px; }
.mt-30 { margin-top: 30px; }
.mt-25 { margin-top: 25px; }
.mt-20 { margin-top: 20px; }
.mt-15 { margin-top: 15px; }
.mt-10 { margin-top: 10px; }
.mt-5 { margin-top: 5px!important; }
.mt-0 { margin-top: 0; }

/*------------------------------------------*/
/*  Margin Top Inverse
/*------------------------------------------*/

.mt-inverse-150 { margin-top: -150px; }
.mt-inverse-140 { margin-top: -140px; }
.mt-inverse-130 { margin-top: -130px; }
.mt-inverse-120 { margin-top: -120px; }
.mt-inverse-110 { margin-top: -110px; }
.mt-inverse-100 { margin-top: -100px; }
.mt-inverse-90 { margin-top: -90px; }
.mt-inverse-80 { margin-top: -80px; }
.mt-inverse-70 { margin-top: -70px; }
.mt-inverse-60 { margin-top: -60px; }
.mt-inverse-50 { margin-top: -50px; }
.mt-inverse-40 { margin-top: -40px; }
.mt-inverse-30 { margin-top: -30px; }
.mt-inverse-20 { margin-top: -20px; }

/*------------------------------------------*/
/*  Margin Bottom
/*------------------------------------------*/

.mb-100 { margin-bottom: 100px; }
.mb-95 { margin-bottom: 95px; }
.mb-90 { margin-bottom: 90px; }
.mb-85 { margin-bottom: 85px; }
.mb-80 { margin-bottom: 80px; }
.mb-75 { margin-bottom: 75px; }
.mb-70 { margin-bottom: 70px; }
.mb-65 { margin-bottom: 65px; }
.mb-60 { margin-bottom: 60px; }
.mb-55 { margin-bottom: 55px; }
.mb-50 { margin-bottom: 50px; }
.mb-45 { margin-bottom: 45px; }
.mb-40 { margin-bottom: 40px; }
.mb-35 { margin-bottom: 35px; }
.mb-30 { margin-bottom: 30px; }
.mb-25 { margin-bottom: 25px; }
.mb-20 { margin-bottom: 20px; }
.mb-15 { margin-bottom: 15px; }
.mb-10 { margin-bottom: 10px; }
.mb-5 { margin-bottom: 5px!important; }
.mb-0 { margin-bottom: 0; }

/*------------------------------------------*/
/*  Margin Left
/*------------------------------------------*/

.ml-100 { margin-left: 100px; }
.ml-95 { margin-left: 95px; }
.ml-90 { margin-left: 90px; }
.ml-85 { margin-left: 85px; }
.ml-80 { margin-left: 80px; }
.ml-75 { margin-left: 75px; }
.ml-70 { margin-left: 70px; }
.ml-60 { margin-left: 60px; }
.ml-55 { margin-left: 55px; }
.ml-50 { margin-left: 50px; }
.ml-45 { margin-left: 45px; }
.ml-40 { margin-left: 40px; }
.ml-35 { margin-left: 35px; }
.ml-30 { margin-left: 30px; }
.ml-25 { margin-left: 25px; }
.ml-20 { margin-left: 20px; }
.ml-15 { margin-left: 15px; }
.ml-10 { margin-left: 10px; }
.ml-5 { margin-left: 5px!important; }

/*------------------------------------------*/
/*  Margin Right
/*------------------------------------------*/

.mr-100 { margin-right: 100px; }
.mr-95 { margin-right: 95px; }
.mr-90 { margin-right: 90px; }
.mr-85 { margin-right: 85px; }
.mr-80 { margin-right: 80px; }
.mr-75 { margin-right: 75px; }
.mr-70 { margin-right: 70px; }
.mr-65 { margin-right: 65px; }
.mr-60 { margin-right: 60px; }
.mr-55 { margin-right: 55px; }
.mr-50 { margin-right: 50px; }
.mr-45 { margin-right: 45px; }
.mr-40 { margin-right: 40px; }
.mr-35 { margin-right: 35px; }
.mr-30 { margin-right: 30px; }
.mr-25 { margin-right: 25px; }
.mr-20 { margin-right: 20px; }
.mr-15 { margin-right: 15px; }
.mr-10 { margin-right: 10px; }
.mr-5 { margin-right: 5px!important; }

/*------------------------------------------*/
/*  Padding Top
/*------------------------------------------*/

.pt-100 { padding-top: 100px; }
.pt-95 { padding-top: 95px; }
.pt-90 { padding-top: 90px; }
.pt-85 { padding-top: 85px; }
.pt-80 { padding-top: 80px; }
.pt-75 { padding-top: 75px; }
.pt-70 { padding-top: 70px; }
.pt-65 { padding-top: 65px; }
.pt-60 { padding-top: 60px; }
.pt-55 { padding-top: 55px; }
.pt-50 { padding-top: 50px; }
.pt-45 { padding-top: 45px; }
.pt-40 { padding-top: 40px; }
.pt-35 { padding-top: 35px; }
.pt-30 { padding-top: 30px; }
.pt-25 { padding-top: 25px; }
.pt-20 { padding-top: 20px; }
.pt-15 { padding-top: 15px; }
.pt-10 { padding-top: 10px; }
.pt-5 { padding-top: 5px!important; }

/*------------------------------------------*/
/*  Padding Bottom
/*------------------------------------------*/

.pb-100 { padding-bottom: 100px; }
.pb-95 { padding-bottom: 95px; }
.pb-90 { padding-bottom: 90px; }
.pb-85 { padding-bottom: 85px; }
.pb-80 { padding-bottom: 80px; }
.pb-75 { padding-bottom: 75px; }
.pb-70 { padding-bottom: 70px; }
.pb-65 { padding-bottom: 65px; }
.pb-60 { padding-bottom: 60px; }
.pb-55 { padding-bottom: 55px; }
.pb-50 { padding-bottom: 50px; }
.pb-45 { padding-bottom: 45px; }
.pb-40 { padding-bottom: 40px; }
.pb-35 { padding-bottom: 35px; }
.pb-30 { padding-bottom: 30px; }
.pb-25 { padding-bottom: 25px; }
.pb-20 { padding-bottom: 20px; }
.pb-15 { padding-bottom: 15px; }
.pb-10 { padding-bottom: 10px; }
.pb-5 { padding-bottom: 5px!important; }

/*------------------------------------------*/
/*  Padding Left
/*------------------------------------------*/

.pl-100 { padding-left: 100px; }
.pl-95 { padding-left: 95px; }
.pl-90 { padding-left: 90px; }
.pl-85 { padding-left: 85px; }
.pl-80 { padding-left: 80px; }
.pl-75 { padding-left: 75px; }
.pl-70 { padding-left: 70px; }
.pl-65 { padding-left: 65px; }
.pl-60 { padding-left: 60px; }
.pl-55 { padding-left: 55px; }
.pl-50 { padding-left: 50px; }
.pl-45 { padding-left: 45px; }
.pl-40 { padding-left: 40px; }
.pl-35 { padding-left: 35px; }
.pl-30 { padding-left: 30px; }
.pl-25 { padding-left: 25px; }
.pl-20 { padding-left: 20px; }
.pl-15 { padding-left: 15px; }
.pl-10 { padding-left: 10px; }
.pl-5 { padding-left: 5px!important; }

/*------------------------------------------*/
/*  Padding Right
/*------------------------------------------*/

.pr-100 { padding-right: 100px; }
.pr-95 { padding-right: 95px; }
.pr-90 { padding-right: 90px; }
.pr-85 { padding-right: 85px; }
.pr-80 { padding-right: 80px; }
.pr-75 { padding-right: 75px; }
.pr-70 { padding-right: 70px; }
.pr-65 { padding-right: 65px; }
.pr-60 { padding-right: 60px; }
.pr-55 { padding-right: 55px; }
.pr-50 { padding-right: 50px; }
.pr-45 { padding-right: 45px; }
.pr-40 { padding-right: 40px; }
.pr-35 { padding-right: 35px; }
.pr-30 { padding-right: 30px; }
.pr-25 { padding-right: 25px; }
.pr-20 { padding-right: 20px; }
.pr-15 { padding-right: 15px; }
.pr-10 { padding-right: 10px; }
.pr-5 { padding-right: 5px!important; }

/*------------------------------------------*/
/*  Padding Centered
/*------------------------------------------*/

.pc-70 { padding-right: 70px; padding-left: 70px; } 
.pc-65 { padding-right: 65px; padding-left: 65px; } 
.pc-60 { padding-right: 60px; padding-left: 60px; } 
.pc-55 { padding-right: 55px; padding-left: 55px; } 
.pc-50 { padding-right: 50px; padding-left: 50px; } 
.pc-45 { padding-right: 45px; padding-left: 45px; } 
.pc-40 { padding-right: 40px; padding-left: 40px; }
.pc-35 { padding-right: 35px; padding-left: 35px; }
.pc-30 { padding-right: 30px; padding-left: 30px; }
.pc-25 { padding-right: 25px; padding-left: 25px; }
.pc-20 { padding-right: 20px; padding-left: 20px; }
.pc-15 { padding-right: 15px; padding-left: 15px; }
.pc-10 { padding-right: 10px; padding-left: 10px; }

/*------------------------------------------*/
/*  BACKGROUND SETTINGS
/*------------------------------------------*/

.rel { position: relative!important; z-index: 3; }

.bg_fixed,
.bg_scroll {
  width: 100%;
  background-repeat: no-repeat;
  background-position: center center;   
  background-size: cover;
}

.bg_scroll {
  background-attachment: fixed!important;
}

/*------------------------------------------*/
/*  Background Colors
/*------------------------------------------*/

.bg_dark { background-color: #282B38!important; }
.bg_white { background-color: #fff!important; }
.bg_snow { background-color: #fdfdfd; }
.bg_whitesmoke { background-color: #f7f7f7; }
.bg_lightgrey { background-color: #efefef; }
.bg_aliceblue { background-color: #f2f2fa; } 
.bg_blue { background-color: #007afc; }
.bg_cornsilk { background-color: #fcf2f0; }
.bg_green { background-color: #61bb46; }
.bg_lavender { background-color: #e6efff; }
.bg_red { background-color: #e03a3e; }
.bg_sapphire { background-color: #3a71b5; }
.bg_sblue { background-color: #3456b5; }
.bg_skyblue { background-color: #009ddc; }
.bg_slateblue { background-color: #8084c3; }
.bg_purple { background-color: #6e24d0; }
.bg_rose { background-color: #ff495a; }
.bg_violet { background: #8e51c7; }
.bg_yellow { background-color: #fcb80b; }

/*------------------------------------------*/
/*  Background Gradients
/*------------------------------------------*/

.blue_gradient { 
  background: -webkit-linear-gradient(to right, #005cda, #006cff 100%);
  background: -o-linear-gradient(to right, #005cda, #006cff 100%);
  background: linear-gradient(to right, #005cda, #006cff 100%);
}

.crocus_gradient { 
  background-image: linear-gradient(196deg, #515bf8, #7440e9);
  background: -o-linear-gradient(196deg, #515bf8, #7440e9);
  background: linear-gradient(196deg, #515bf8, #7440e9);
}

.lavender_gradient { 
  background: -webkit-linear-gradient(180deg, #e6efff 0%, #fafcff 100%);
  background: -o-linear-gradient(180deg, #e6efff 0%, #fafcff 100%);
  background: linear-gradient(180deg, #e6efff 0%, #fafcff 100%);
}

.purple_gradient { 
  background: -webkit-linear-gradient(235deg, #cb6ddb 0%, #3426b7 100%);
  background: -o-linear-gradient(235deg, #cb6ddb 0%, #3426b7 100%);
  background: linear-gradient(-135deg, #cb6ddb 0%, #3426b7 100%);
}

.violet_gradient {
  background: -webkit-linear-gradient(left top,#a85cc6,#3023ae);
  background: -o-linear-gradient(left top,#a85cc6,#3023ae);
  background: -moz-linear-gradient(left top,#a85cc6,#3023ae);
}

.orange_gradient { 
  background: -webkit-linear-gradient(220deg, #f4a638 0%, #d4303e 100%);
  background: -o-linear-gradient(220deg, #f4a638 0%, #d4303e 100%);
  background: linear-gradient(220deg, #f4a638 0%, #d4303e 100%);
}

.sapphire_gradient { 
  background: linear-gradient(rgba(0, 0, 0, 0.2) 0%, rgba(0, 0, 0, 0) 100%), linear-gradient(rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.2) 100%), 
  linear-gradient(209.21deg, rgb(65, 108, 226) 13.57%, rgb(86, 215, 222) 98.38%);
}

.teal_gradient { 
  background: -webkit-linear-gradient(220deg, #c4e9ec 0%, #7da9bf 100%);
  background: -o-linear-gradient(220deg, #c4e9ec 0%, #7da9bf 100%);
  background: linear-gradient(220deg, #c4e9ec 0%, #7da9bf 100%);
}

/*------------------------------------------*/
/*  Background Images for Sections
/*------------------------------------------*/

.bg_chat, .bg_fit_01, .bg_fit_02, .bg_mobile, .bg_bank_01, .bg_bank_02, 
.bg_purple_img, .bg_sblue_img, .bg_selfy, .bg_pattern_01, .bg_pattern_02, .bg_pattern_03 {
  background-repeat: no-repeat;
  background-position: center center;   
  background-size: cover;
  background-attachment: fixed!important;
}

.bg_chat { background-image: url(./images/bg_chat.jpg); background-attachment: scroll!important; }
.bg_fit_01 { background-image: url(./images/bg_fit_01.jpg); background-attachment: scroll!important; }
.bg_fit_02 { background-image: url(./images/background-bottom.jpg); }
.bg_mobile { background-image: url(./images/bg_mobile.jpg); background-attachment: scroll!important; }
.bg_bank_01 { background-image: url(./images/bg_bank_01.jpg); background-attachment: scroll!important; }
.bg_bank_02 { background-image: url(./images/bg_bank_02.jpg); background-attachment: scroll!important; }
.bg_purple_img { background-image: url(./images/bg_purple_img.jpg); }
.bg_sblue_img { background-image: url(./images/bg_sblue_img.jpg); }
.bg_selfy { background-image: url(./images/bg_selfy.jpg); background-attachment: scroll!important; }
.bg_pattern_01 { background-image: url(./images/bg_shape_01.png); }
.bg_pattern_02 { background-image: url(./images/bg_shape_02.png); }
.bg_pattern_03 { background-image: url(./images/bg_shape_03.png); }

/*------------------------------------------*/
/*  Background Shapes
/*------------------------------------------*/

.bg_shape_01:before,
.bg_shape_02:before,
.bg_shape_03:before {
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  background-attachment: fixed!important;
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  z-index: -3;
}

.bg_shape_01:before {
  background-image: url(./images/bg_shape_01.png);
}

.bg_shape_02:before {
  background-image: url(./images/bg_shape_02.png);
}

.bg_shape_03:before {
  background-image: url(./images/bg_shape_03.png);
}

/*------------------------------------------*/
/*  TRANSPARENT BACKGROUND
/*------------------------------------------*/

.bg_el_01, .bg_el_02, .bg_el_03, .bg_el_04, .bg_el_05 {
  position: absolute;
  z-index: -2;
  background-repeat: no-repeat;
  background-size: cover;
}

.bg_el_01 { background-image: url(./images/bg_el_01.png); width: 140px; height: 140px; top: -10%; right: 6%; }
.bg_el_02 { background-image: url(./images/bg_el_02.png); width: 420px; height: 420px; top: 12%; left: -40px; }
.bg_el_03 { background-image: url(./images/bg_el_03.png); width: 210px; height: 210px; bottom: -20px; left: 27%; }
.bg_el_04 { background-image: url(./images/bg_el_04.png); width: 130px; height: 130px; bottom: -5px; left: 34%; }
.bg_el_05 { background-image: url(./images/bg_el_02.png); width: 450px; height: 450px; top: 15%; right: 8%; }

/*------------------------------------------*/
/*  Background Overlays for Sections
/*------------------------------------------*/

.top_overlay,
.wave_overlay,
.bottom_overlay,
.incline_overlay {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
}

.geometric_overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  background-image: url(./images/geometric_overlay.png);
  height: 665px;
  z-index: -1;
}

.top_overlay {
  background-image: url(./images/top_overlay.png);
  height: 48px;
}

.bottom_overlay {
  background-image: url(./images/bottom_overlay.png);
  height: 200px;
}

.wave_overlay {
  background-image: url(./images/wave_overlay.png);
  height: 155px;
}

.incline_overlay {
  background-image: url(./images/incline_overlay.png);
  height: 200px;
}

/*------------------------------------------*/
/*  BORDER SETTINGS FOR DEMOS
/*------------------------------------------*/

.b-border { border: 1px solid #ddd; }
.b-top { border-top: 1px solid #ddd; }
.b-bottom { border-bottom: 1px solid #ddd; }
.b-left { border-left: 1px solid #ddd; }
.b-right { border-right: 1px solid #ddd; }

/*------------------------------------------*/
/*  SECTION DIVIDER
/*------------------------------------------*/

.section-divider {
  display: inline-block;
  max-width: 1150px;
  position: relative;
  width: 100%;
  border: 0;
  height: 2px;
  background-image: linear-gradient(90deg,white 0%,#f1f1f1 12%,#dedede 50%,#f4f4f4 88%,white 100%);
}

.section-divider:before {
  position: absolute;
  content: "";
  display: inline-block;
  width: 30px;
  height: 30px;
  transform: rotate(45deg);
  border-bottom: 2px solid #dedede;
  border-right: 2px solid #dedede;
  top: -14px;
  left: 49%;
  background: #fff;
  z-index: 2;
}

.bg_whitesmoke .section-divider:before {
  background: #f7f7f7;
}




/* ==========================================================================
  02. TYPOGRAPHY
  =========================================================================== */

/*------------------------------------------*/
/*  HEADERS
/*------------------------------------------*/

h1, h2, h3, h4, h5, h6 {  
  color: #463853;
  font-family: "Helvetica-Neue", sans-serif;
  font-weight: 500;
  letter-spacing: 0;
}

/* Header H6 */
h6.h6-xs { font-size: 1.0625rem; }   /* 17px */
h6.h6-sm { font-size: 1.125rem; }    /* 18px */
h6.h6-md { font-size: 1.15625rem; }  /* 18.5px */
h6.h6-lg { font-size: 1.1875rem; }   /* 19px */
h6.h6-xl { font-size: 1.21875rem; }  /* 19.5px */

/* Header H5 */
h5.h5-xs { font-size: 1.25rem; }    /* 20px */
h5.h5-sm { font-size: 1.375rem; }   /* 22px */
h5.h5-md { font-size: 1.5rem; }     /* 24px */
h5.h5-lg { font-size: 1.625rem; }   /* 26px */
h5.h5-xl { font-size: 1.75rem; }    /* 28px */

/* Header H4 */
h4.h4-xs { font-size: 1.875rem; }   /* 30px */
h4.h4-sm { font-size: 2rem; }       /* 32px */
h4.h4-md { font-size: 2.125rem; }   /* 34px */
h4.h4-lg { font-size: 2.25rem; }    /* 36px */
h4.h4-xl { font-size: 2.375rem; }   /* 38px */

h3.h3-xs { font-size: 2.5rem; }     /* 40px */
h3.h3-sm { font-size: 2.625rem; }   /* 42px */
h3.h3-md { font-size: 2.75rem; }    /* 44px */
h3.h3-lg { font-size: 2.875rem; }   /* 46px */
h3.h3-xl { font-size: 3rem; }       /* 48px */

/* Header H2 */
h2.h2-xs { font-size: 3.125rem; }   /* 50px */
h2.h2-sm { font-size: 3.25rem; }    /* 52px */
h2.h2-md { font-size: 3.375rem; }   /* 54px */
h2.h2-lg { font-size: 3.5rem; }     /* 56px */ 
h2.h2-xl { font-size: 3.75rem; }    /* 60px */

h2.h2-title-xs { font-size: 4.0625rem; } /* 65px */
h2.h2-title-sm { font-size: 4.375rem; }  /* 70px */
h2.h2-title-md { font-size: 4.6875rem; } /* 75px */
h2.h2-title-lg { font-size: 5rem; }      /* 80px */
h2.h2-title-xl { font-size: 5.3125rem; } /* 85px */

/*------------------------------------------*/
/*  PARAGRAPHS
/*------------------------------------------*/

p { font-size: 1.0625rem; }  /* 17px */
p.p-md { font-size: 1.15625rem; line-height: 1.6; }  /* 18.5px */
/* 7/29/2022 - Hong Huie - fixed selector syntax issue below. It was
   just "p.".  I changed it to "p.lg" */
p.lg { font-size: 1.1875rem;  line-height: 1.6; }  /* 19px */
p.p-xl { font-size: 1.25rem;  line-height: 1.6; }    /* 20px */

/*------------------------------------------*/
/*  LINK SETTINGS
/*------------------------------------------*/

a {
  color: #998da0;
  text-decoration: none;
  -webkit-transition: all 400ms ease-in-out;
  -moz-transition: all 400ms ease-in-out;
  -o-transition: all 400ms ease-in-out;
  -ms-transition: all 400ms ease-in-out;
  transition: all 400ms ease-in-out; 
}

a:hover {
  color: #666;
  text-decoration: none;
}

a:focus {
  outline: none;
  text-decoration: none;
}

/*------------------------------------------*/
/*  LISTS
/*------------------------------------------*/

/* Hong Huie - 8/3/2022 - Commented Out */
/* ul {
  margin: 0;
  padding: 0;
  list-style: none;
}  */

/*------------------------------------------*/
/*  TEXT LIST
/*------------------------------------------*/

.txt-list li i {
  position: relative;
  font-size: 1.05rem;
  line-height: 1.5rem;
  float: left;
  margin-right: 10px;
  top: 2px;
}

.txt-list li p {
  overflow: hidden;
  margin-bottom: 10px;
}

ul.simple-list {
  list-style: disc;
  margin-left: 15px;
}

/*------------------------------------------*/
/*  NUMBER LIST
/*------------------------------------------*/

ol.num-list {
  margin-left: -20px;
}

ol.num-list li p {
  padding-left: 5px;
  margin-bottom: 5px;
}

ol.digit-list {
  padding: 0;
  margin-left: 15px;
}

ol.digit-list p {
  margin-bottom: 8px;
}

/*------------------------------------------*/
/*  BOX LIST
/*------------------------------------------*/

.box-list p {
  position: relative;
  font-weight: 400;
  padding-bottom: 8px;
  margin-bottom: 8px;
  border-bottom: 1px dashed #ddd;
}

.bg_dark .box-list p {
  border-bottom: 1px dashed #777;
}

.box-list p:last-child {
  position: relative;
  padding-bottom: 0;
  margin-bottom: 0;
  border-bottom: none;
}

.box-list p span {
  font-weight: 700;
  position: absolute;
  top: 0;
  right: 15px;
}

.bg_dark .box-list p span {
  color: #fff;
}

/*------------------------------------------*/
/*  BUTTON SETTINGS
/*------------------------------------------*/

.btn {
  background-color: transparent;
  font-family: "Google Sans", Helvetica, Arial, sans-serif;
  color: #fff;
  font-size: 1.1rem;
  line-height: 1;
  font-weight: 500;
  padding: 14px 44px;
  border: 2px solid transparent;
  -webkit-border-radius: 100px;
  -moz-border-radius: 100px;
  border-radius: 100px;
  -webkit-transition: all 400ms ease-in-out;
  -moz-transition: all 400ms ease-in-out;
  -o-transition: all 400ms ease-in-out;
  -ms-transition: all 400ms ease-in-out;
  transition: all 400ms ease-in-out; 
}

.ico-right.ico-20 [class*="flaticon-"]:before, 
.ico-right.ico-20 [class*="flaticon-"]:after { font-size: 1.2rem; } 

/*------------------------------------------*/
/*  Button Icon
/*------------------------------------------*/

.ico-left .btn span {
  margin-right: 4px;
}

.ico-right .btn span {
  position: relative;
  top: 3px;
  left: 4px;
}

/*------------------------------------------*/
/*  Button Size
/*------------------------------------------*/

.btn.btn-md {
  font-size: 1.1675rem;
  padding: 15px 44px;
}

/*------------------------------------------*/
/*  Button Color
/*------------------------------------------*/

.btn-white, 
.white-color .btn-white {
  color: #333;
  background-color: #fff;
  border-color: #fff!important;
}

.btn-tra-white {
  color: #fff;
  background-color: transparent;
  border-color: #fff!important;
}

.scroll .btn-tra-white {
  color: #333!important;
  background-color: transparent;
  border-color: #333!important;
}

.btn-grey, 
.white-color .btn-grey {
  color: #666;
  background-color: #eee;
  border-color: #eee!important;
}

.btn-tra-grey, 
.white-color .btn-tra-grey {
  color: #333;
  background-color: transparent;
  border-color: #ccc!important;
}

.btn-black,
.white-color .btn-black {
  color: #fff;
  background-color: #333;
  border-color: #333;
}

.btn-tra-black,
.white-color .btn-tra-black {
  color: #333;
  background-color: transparent;
  border-color: #333;
}

.btn-avocado, 
.scroll .btn-avocado, 
.white-color .btn-avocado,
.avocado-hover:hover, 
.scroll .avocado-hover:hover, 
.white-color .avocado-hover:hover {
  color: #333!important;
  background-color: #f1ff51;
  border-color: #f1ff51!important;
}

.btn-tra-avocado, 
.scroll .btn-tra-avocado, 
.white-color .btn-tra-avocado,
.tra-avocado-hover:hover, 
.scroll .tra-avocado-hover:hover, 
.white-color .tra-avocado-hover:hover {
  color: #f1ff51!important;
  background-color: transparent;
  border-color: #f1ff51!important;
}

.btn-blue, 
.scroll .btn-blue, 
.white-color .btn-blue,
.blue-hover:hover, 
.scroll .blue-hover:hover, 
.white-color .blue-hover:hover {
  color: #fff!important;
  background-color: #007afc;
  border-color: #007afc!important;
}

.btn-tra-blue, 
.scroll .btn-tra-blue, 
.white-color .btn-tra-blue,
.tra-blue-hover:hover, 
.scroll .tra-blue-hover:hover, 
.white-color .tra-blue-hover:hover {
  color: #007afc!important;
  background-color: transparent;
  border-color: #007afc!important;
}

.btn-green, 
.scroll .btn-green, 
.white-color .btn-green,
.green-hover:hover, 
.scroll .green-hover:hover, 
.white-color .green-hover:hover {
  color: #fff!important;
  background-color: #61bb46;
  border-color: #61bb46!important;
}

.btn-tra-green, 
.scroll .btn-tra-green, 
.white-color .btn-tra-green,
.tra-green-hover:hover, 
.scroll .tra-green-hover:hover, 
.white-color .tra-green-hover:hover {
  color: #61bb46!important;
  background-color: transparent;
  border-color: #61bb46!important;
}

.btn-sapphire, 
.scroll .btn-sapphire, 
.white-color .btn-sapphire,
.sapphire-hover:hover, 
.scroll .sapphire-hover:hover, 
.white-color .sapphire-hover:hover {
  color: #fff!important;
  background-color: #3a71b5;
  border-color: #3a71b5!important;
}

.btn-tra-sapphire, 
.scroll .btn-tra-sapphire, 
.white-color .btn-tra-sapphire,
.tra-sapphire-hover:hover, 
.scroll .tra-sapphire-hover:hover, 
.white-color .tra-sapphire-hover:hover {
  color: #3a71b5!important;
  background-color: transparent;
  border-color: #3a71b5!important;
}

.btn-steelblue, 
.scroll .btn-steelblue, 
.white-color .btn-steelblue,
.steelblue-hover:hover, 
.scroll .steelblue-hover:hover, 
.white-color .steelblue-hover:hover {
  color: #fff!important;
  background-color: #2a80b9;
  border-color: #2a80b9!important;
}

.btn-tra-steelblue, 
.scroll .btn-tra-steelblue, 
.white-color .btn-tra-steelblue,
.tra-steelblue-hover:hover, 
.scroll .tra-steelblue-hover:hover, 
.white-color .tra-steelblue-hover:hover {
  color: #2a80b9!important;
  background-color: transparent;
  border-color: #2a80b9!important;
}

.btn-purple, 
.scroll .btn-purple, 
.white-color .btn-purple,
.purple-hover:hover, 
.scroll .purple-hover:hover, 
.white-color .purple-hover:hover {
  color: #fff!important;
  background-color: #6e24d0;
  border-color: #6e24d0!important;
}

.btn-tra-purple, 
.scroll .btn-tra-purple, 
.white-color .btn-tra-purple,
.tra-purple-hover:hover, 
.scroll .tra-purple-hover:hover, 
.white-color .tra-purple-hover:hover {
  color: #6e24d0!important;
  background-color: transparent;
  border-color: #6e24d0!important;
}

.btn-red, 
.scroll .btn-red, 
.white-color .btn-red,
.red-hover:hover, 
.scroll .red-hover:hover, 
.white-color .red-hover:hover {
  color: #fff!important;
  background-color: #821E0D;
  border-color: #821E0D!important;
}

.btn-tra-red, 
.scroll .btn-tra-red, 
.white-color .btn-tra-red,
.tra-red-hover:hover, 
.scroll .tra-red-hover:hover, 
.white-color .tra-red-hover:hover {
  color: #821E0D!important;
  background-color: transparent;
  border-color: #821E0D!important;
}

.btn-rose, 
.scroll .btn-rose, 
.white-color .btn-rose,
.rose-hover:hover, 
.scroll .rose-hover:hover, 
.white-color .rose-hover:hover {
  color: #fff!important;
  background-color: #ff495a;
  border-color: #ff495a!important;
}

.btn-tra-rose, 
.scroll .btn-tra-rose, 
.white-color .btn-tra-rose,
.tra-rose-hover:hover, 
.scroll .tra-rose-hover:hover, 
.white-color .tra-rose-hover:hover {
  color: #ff495a!important;
  background-color: transparent;
  border-color:#ff495a!important;
}

.btn-skyblue, 
.scroll .btn-skyblue, 
.white-color .btn-skyblue,
.skyblue-hover:hover, 
.scroll .skyblue-hover:hover, 
.white-color .skyblue-hover:hover {
  color: #fff!important;
  background-color: #009ddc;
  border-color: #009ddc!important;
}

.btn-tra-skyblue, 
.scroll .btn-tra-skyblue, 
.white-color .btn-tra-skyblue,
.tra-skyblue-hover:hover, 
.scroll .tra-skyblue-hover:hover, 
.white-color .tra-skyblue-hover:hover {
  color: #009ddc!important;
  background-color: transparent;
  border-color: #009ddc!important;
}

.btn-violet, 
.scroll .btn-violet, 
.white-color .btn-violet,
.violet-hover:hover, 
.scroll .violet-hover:hover, 
.white-color .violet-hover:hover {
  color: #fff!important;
  background-color: #8e51c7;
  border-color: #8e51c7!important;
}

.btn-tra-violet, 
.scroll .btn-tra-violet, 
.white-color .btn-tra-violet,
.tra-violet-hover:hover, 
.scroll .tra-violet-hover:hover, 
.white-color .tra-violet-hover:hover {
  color: #8e51c7!important;
  background-color: transparent;
  border-color: #8e51c7!important;
}

.btn-yellow, 
.scroll .btn-yellow, 
.white-color .btn-yellow,
.yellow-hover:hover, 
.scroll .yellow-hover:hover, 
.white-color .yellow-hover:hover {
  color: #333!important;
  background-color: #fdb827;
  border-color: #fdb827!important;
}

/*------------------------------------------*/
/*  Button Hover
/*------------------------------------------*/

.white-hover:hover,
.scroll .white-hover:hover {
  color: #333!important;
  background-color: #fff;
  border-color: #fff!important;
}

.tra-white-hover:hover,
.white-color .tra-white-hover:hover {
  color: #fff!important;
  background-color: transparent;
  border-color: #fff!important;
}

.scroll .tra-white-hover:hover {
  color: #333!important;
  background-color: transparent;
  border-color: #333!important;
}

.dark-menu .scroll .tra-white-hover:hover {
  color: #fff!important;
  background-color: transparent;
  border-color: #fff!important;
}

.black-hover:hover,
.scroll .black-hover:hover {
  color: #fff!important;
  background-color: #333;
  border-color: #333!important;
}

.tra-black-hover:hover,
.scroll .tra-black-hover:hover
.white-color .tra-black-hover:hover {
  color: #333!important;
  background-color: transparent;
  border-color: #333!important;
}

.grey-hover:hover,
.scroll .grey-hover:hover {
  color: #333!important;
  background-color: #ccc;
  border-color: #ccc!important;
}

.tra-grey-hover:hover,
.scroll .tra-grey-hover:hover {
  color: #333!important;
  background-color: transparent;
  border-color: #ccc!important;
}

/*------------------------------------------*/
/*  Button Focus
/*------------------------------------------*/

.btn:focus {
  color: #fff; 
  -webkit-box-shadow: none;
  box-shadow: none; 
}

.btn.btn-black:focus {
  color: #fff; 
  -webkit-box-shadow: none;
  box-shadow: none; 
}

.btn-grey:focus {
  color: #666; 
  -webkit-box-shadow: none;
  box-shadow: none; 
}

.btn-tra-black:focus, 
.btn.btn-tra-grey:focus {
  color: #333; 
  -webkit-box-shadow: none;
  box-shadow: none; 
}

/*------------------------------------------*/
/*  VIDEO LINK
/*------------------------------------------*/

.btn-md.btn-video-link {
  color: #666;
  font-size: 18px;
  padding: 10px 8px;
}

.btn-md.btn-video-link span {
  position: relative;
  top: 5px;
  right: 6px;
}

.btn-md.btn-video-link {
  line-height: 30px!important;
}

/*------------------------------------------*/
/*  VIDEO POPUP ICON
/*------------------------------------------*/

.video-preview {
  position: relative; 
  text-align: center;
}

.video-btn {
  position: absolute!important;
  top: 50%;
  left: 50%;
  display: inline-block;
  text-align: center;
  color: #fff;
  -webkit-border-radius: 100%;
  -moz-border-radius: 100%;
  border-radius: 100%; 
}

.video-btn-xl {
  width: 120px;
  height: 120px;
  margin-top: -60px;
  margin-left: -60px; 
}

.video-btn-lg {
  width: 100px;
  height: 100px;
  margin-top: -50px;
  margin-left: -50px; 
}

.video-btn-md {
  width: 80px;
  height: 80px;
  margin-top: -40px;
  margin-left: -40px; 
}

.video-btn-sm {
  width: 60px;
  height: 60px;
  margin-top: -30px;
  margin-left: -30px; 
}

.video-block-wrapper {
  -webkit-transition: all 400ms ease-in-out;
  -moz-transition: all 400ms ease-in-out;
  -o-transition: all 400ms ease-in-out;
  -ms-transition: all 400ms ease-in-out;
  transition: all 400ms ease-in-out;
}

.video-btn.video-btn-xl [class^="flaticon-"]:before, 
.video-btn.video-btn-xl [class^="flaticon-"]:after {
  line-height: 120px!important;   
  margin-left: 10px;
}

.video-btn.video-btn-lg [class^="flaticon-"]:before, 
.video-btn.video-btn-lg [class^="flaticon-"]:after {
  line-height: 100px!important;   
  margin-left: 8px;
}

.video-btn.video-btn-md [class^="flaticon-"]:before, 
.video-btn.video-btn-md [class^="flaticon-"]:after {
  line-height: 80px!important;   
  margin-left: 7px;
}

.video-btn.video-btn-sm [class^="flaticon-"]:before, 
.video-btn.video-btn-sm [class^="flaticon-"]:after {
  line-height: 60px!important;   
  margin-left: 6px;
}

.video-btn:hover .video-block-wrapper {
  -moz-transform: scale(0.95);
  -ms-transform: scale(0.95);
  -webkit-transform: scale(0.95);
  -o-transform: scale(0.95);
  transform: scale(0.95);
}

.video-btn:before {
  content: '';
  position: absolute;
  left: -5px;
  right: -5px;
  top: -5px;
  bottom: -5px;
  background: rgba(255, 255, 255, 0.2);
  opacity: 0;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  border-radius: 50%;
  -webkit-transition: all 400ms ease-in-out;
  -moz-transition: all 400ms ease-in-out;
  -o-transition: all 400ms ease-in-out;
  -ms-transition: all 400ms ease-in-out;
  transition: all 400ms ease-in-out;
}

.video-btn.video-btn-xl:hover:before {
  opacity: .75;
  left: -32px;
  right: -32px;
  top: -32px;
  bottom: -32px;
}

.video-btn.video-btn-lg:hover:before {
  opacity: .75;
  left: -25px;
  right: -25px;
  top: -25px;
  bottom: -25px;
}

.video-btn.video-btn-md:hover:before {
  opacity: .75;
  left: -18px;
  right: -18px;
  top: -18px;
  bottom: -18px;
}

.video-btn.video-btn-sm:hover:before {
  opacity: .75;
  left: -12px;
  right: -12px;
  top: -12px;
  bottom: -12px;
}

/*------------------------------------------*/
/*  STORE BADGE ICONS
/*------------------------------------------*/

.stores-badge {
  display: inline-block;
}

.store {
  display: inline-block;
  text-align: center;
  float: left;
  margin-right: 15px;
}

.store:last-child {
  margin-right: 0;
} 

.footer .store {
  text-align: right;
  display: block;
  float: none;
  margin-right: 0;
  margin-bottom: 15px;
}

.store img {
  width: auto;
  max-width: inherit;
  max-height: 50px;
}

.store.header-store img {
  max-height: 44px;
}

#download-3 .store img {
  max-height: 55px;
}

.footer .store img {
  display: inline-block;
  max-height: 45px;
}

span.os-version {
  display: block;
  font-size: 1.0625rem;
  line-height: 1; 
  margin-top: 15px; 
}

/*------------------------------------------*/
/*  VECTOR ICONS
/*------------------------------------------*/

.ico-15 [class*="flaticon-"]:before, .ico-15 [class*="flaticon-"]:after { font-size: 0.9375rem; }   /* 15px */
.ico-20 [class*="flaticon-"]:before, .ico-20 [class*="flaticon-"]:after { font-size: 1.25rem; }     /* 20px */
.ico-25 [class*="flaticon-"]:before, .ico-25 [class*="flaticon-"]:after { font-size: 1.5625rem; }   /* 25px */
.ico-30 [class*="flaticon-"]:before, .ico-30 [class*="flaticon-"]:after { font-size: 1.875rem; }    /* 30px */
.ico-35 [class*="flaticon-"]:before, .ico-35 [class*="flaticon-"]:after { font-size: 2.1875rem; }   /* 35px */
.ico-40 [class*="flaticon-"]:before, .ico-40 [class*="flaticon-"]:after { font-size: 2.5rem; }      /* 40px */
.ico-45 [class*="flaticon-"]:before, .ico-45 [class*="flaticon-"]:after { font-size: 2.8125rem; }   /* 45px */
.ico-50 [class*="flaticon-"]:before, .ico-50 [class*="flaticon-"]:after { font-size: 3.125rem; }    /* 50px */
.ico-55 [class*="flaticon-"]:before, .ico-55 [class*="flaticon-"]:after { font-size: 3.4375rem; }   /* 55px */
.ico-60 [class*="flaticon-"]:before, .ico-60 [class*="flaticon-"]:after { font-size: 3.75rem; }     /* 60px */
.ico-65 [class*="flaticon-"]:before, .ico-65 [class*="flaticon-"]:after { font-size: 4.0625rem; }   /* 65px */
.ico-70 [class*="flaticon-"]:before, .ico-70 [class*="flaticon-"]:after { font-size: 4.375rem; }    /* 70px */
.ico-75 [class*="flaticon-"]:before, .ico-75 [class*="flaticon-"]:after { font-size: 4.6875rem; }   /* 75px */
.ico-80 [class*="flaticon-"]:before, .ico-80 [class*="flaticon-"]:after { font-size: 5rem; }        /* 80px */
.ico-85 [class*="flaticon-"]:before, .ico-85 [class*="flaticon-"]:after { font-size: 5.3125rem; }   /* 85px */
.ico-90 [class*="flaticon-"]:before, .ico-90 [class*="flaticon-"]:after { font-size: 5.625rem; }    /* 90px */
.ico-95 [class*="flaticon-"]:before, .ico-95 [class*="flaticon-"]:after { font-size: 5.9375rem; }   /* 95px */
.ico-100 [class*="flaticon-"]:before, .ico-100 [class*="flaticon-"]:after { font-size: 6.25rem; }   /* 100px */
.ico-105 [class*="flaticon-"]:before, .ico-105 [class*="flaticon-"]:after { font-size: 6.5625rem; } /* 105px */
.ico-110 [class*="flaticon-"]:before, .ico-110 [class*="flaticon-"]:after { font-size: 6.875rem; }  /* 110px */
.ico-115 [class*="flaticon-"]:before, .ico-115 [class*="flaticon-"]:after { font-size: 7.1875rem; } /* 115px */
.ico-120 [class*="flaticon-"]:before, .ico-120 [class*="flaticon-"]:after { font-size: 7.5rem; }    /* 120px */
.ico-125 [class*="flaticon-"]:before, .ico-125 [class*="flaticon-"]:after { font-size: 7.8125rem; } /* 125px */

/*------------------------------------------*/
/*   PNG ICONS SETTINGS
/*------------------------------------------*/ 

.ico-150 img { width: 150px; height: 150px; }
.ico-145 img { width: 145px; height: 145px; }
.ico-140 img { width: 140px; height: 140px; }
.ico-135 img { width: 135px; height: 135px; }
.ico-130 img { width: 130px; height: 130px; }
.ico-125 img { width: 125px; height: 125px; }
.ico-120 img { width: 120px; height: 120px; }
.ico-115 img { width: 115px; height: 115px; }
.ico-110 img { width: 110px; height: 110px; }
.ico-105 img { width: 105px; height: 105px; }
.ico-100 img { width: 100px; height: 100px; }
.ico-95 img { width: 95px; height: 95px; }
.ico-90 img { width: 90px; height: 90px; }
.ico-85 img { width: 85px; height: 85px; }
.ico-80 img { width: 80px; height: 80px; }
.ico-75 img { width: 75px; height: 75px; }
.ico-70 img { width: 70px; height: 70px; }
.ico-65 img { width: 65px; height: 65px; }
.ico-60 img { width: 60px; height: 60px; }
.ico-55 img { width: 55px; height: 55px; }
.ico-50 img { width: 50px; height: 50px; }
.ico-45 img { width: 45px; height: 45px; }
.ico-40 img { width: 40px; height: 40px; }
.ico-35 img { width: 35px; height: 35px; }
.ico-30 img { width: 30px; height: 30px; }
.ico-25 img { width: 25px; height: 25px; }
.ico-20 img { width: 20px; height: 20px; }

/*------------------------------------------*/
/*   SECTION ID
/*------------------------------------------*/

.section-id {
  display: block;
  font-family: "Google Sans", Helvetica, Arial, sans-serif;
  font-size: 1.1875rem;
  font-weight: 500;
  letter-spacing: 0.5px;
  margin-bottom: 35px;   
}

.section-id.grey-color {
  color: #998da0;
  opacity: .65;
}

/*------------------------------------------*/
/*  SECTION TITLE
/*------------------------------------------*/

.section-title p {
  padding: 0 12%; 
  margin-top: 20px;
  margin-bottom: 0;
}

/*------------------------------------------*/
/*  FONT SETTINGS
/*------------------------------------------*/

.txt-300 { font-weight: 300; }
.txt-400 { font-weight: 400; }
.txt-500 { font-weight: 500; }
.txt-700 { font-weight: 700; }
.txt-900 { font-weight: 900; }

.txt-upcase { text-transform: uppercase }

/*------------------------------------------*/
/*  TEXT COLORS
/*------------------------------------------*/

.white-color,
.white-color h2, 
.white-color h3, 
.white-color h4, 
.white-color h5, 
.white-color h6, 
.white-color p, 
.white-color a, 
.white-color li,
.white-color i, 
.white-color span { color: #fff; }

.dark-color,
.dark-color h2, 
.dark-color h3, 
.dark-color h4, 
.dark-color h5, 
.dark-color h6, 
.dark-color p, 
.dark-color a,
.dark-color li, 
.dark-color i, 
.dark-color span,
.white-color .dark-color { color: #333; }

.grey-color,
.grey-color h2, 
.grey-color h3, 
.grey-color h4, 
.grey-color h5, 
.grey-color h6, 
.grey-color p, 
.grey-color a,
.grey-color li, 
.grey-color i, 
.grey-color span,
.white-color .grey-color { color: #757575; }

.lightgrey-color,
.lightgrey-color h2, 
.lightgrey-color h3, 
.lightgrey-color h4, 
.lightgrey-color h5, 
.lightgrey-color h6, 
.lightgrey-color p, 
.lightgrey-color a,
.lightgrey-color li, 
.lightgrey-color i, 
.lightgrey-color span,
.white-color .lightgrey-color { color: #ccc; }

.blue-color,
.blue-color h2, 
.blue-color h3, 
.blue-color h4, 
.blue-color h5, 
.blue-color h6, 
.blue-color p, 
.blue-color a, 
.blue-color li,
.blue-color i, 
.blue-color span,
.white-color .blue-color { color: #007afc; }

.green-color,
.green-color h2, 
.green-color h3, 
.green-color h4, 
.green-color h5, 
.green-color h6, 
.green-color p, 
.green-color a, 
.green-color li,
.green-color i, 
.green-color span,
.white-color .green-color { color: #61bb46; }

.purple-color,
.purple-color h2, 
.purple-color h3, 
.purple-color h4, 
.purple-color h5, 
.purple-color h6, 
.purple-color p, 
.purple-color a, 
.purple-color li,
.purple-color i, 
.purple-color span,
.white-color .purple-color { color: #454c9d; }

.rose-color,
.rose-color h2, 
.rose-color h3, 
.rose-color h4, 
.rose-color h5, 
.rose-color h6, 
.rose-color p, 
.rose-color a, 
.rose-color li,
.rose-color i, 
.rose-color span,
.white-color .rose-color { color: #ff495a; }

.sapphire-color,
.sapphire-color h2, 
.sapphire-color h3, 
.sapphire-color h4, 
.sapphire-color h5, 
.sapphire-color h6, 
.sapphire-color p, 
.sapphire-color a, 
.sapphire-color li,
.sapphire-color i, 
.sapphire-color span,
.white-color .sapphire-color { color: #3a71b5; }

.skyblue-color,
.skyblue-color h2, 
.skyblue-color h3, 
.skyblue-color h4, 
.skyblue-color h5, 
.skyblue-color h6, 
.skyblue-color p, 
.skyblue-color a, 
.skyblue-color li,
.skyblue-color i, 
.skyblue-color span,
.white-color .skyblue-color { color: #009ddc; }

.stateblue-color,
.stateblue-color h2, 
.stateblue-color h3, 
.stateblue-color h4, 
.stateblue-color h5, 
.stateblue-color h6, 
.stateblue-color p, 
.stateblue-color a, 
.stateblue-color li,
.stateblue-color i, 
.stateblue-color span,
.white-color .stateblue-color { color: #746d78; }

.violet-color,
.violet-color h2, 
.violet-color h3, 
.violet-color h4, 
.violet-color h5, 
.violet-color h6, 
.violet-color p, 
.violet-color a, 
.violet-color li,
.violet-color i, 
.violet-color span,
.white-color .violet-color { color: #8e51c7; }

.yellow-color,
.yellow-color h2, 
.yellow-color h3, 
.yellow-color h4, 
.yellow-color h5, 
.yellow-color h6, 
.yellow-color p, 
.yellow-color a, 
.yellow-color li,
.yellow-color i, 
.yellow-color span,
.white-color .yellow-color { color: #fcb80b; }

/*------------------------------------------*/
/*  TEXT GRADIENT COLORS
/*------------------------------------------*/

.text-purple-gradient {
  background: -webkit-linear-gradient(235deg, #cb6ddb 0%, #3426b7 100%);
  background: -o-linear-gradient(235deg, #cb6ddb 0%, #3426b7 100%);
  background: linear-gradient(-135deg, #cb6ddb 0%, #3426b7 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.text-violet-gradient {
  background: -webkit-linear-gradient(left top,#a85cc6,#3023ae);
  background: -o-linear-gradient(left top,#a85cc6,#3023ae);
  background: -moz-linear-gradient(left top,#a85cc6,#3023ae);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

/*------------------------------------------*/
/*  TEXT BLOCK
/*------------------------------------------*/ 

.txt-block.left-column {
  padding-left: 40px;
  padding-right: 20px;
}

.txt-block.right-column {
  padding-right: 40px;
  padding-left: 20px;
}

/*------------------------------------------*/
/*  TEXT BLOCK TYPOGRAPHY
/*------------------------------------------*/ 

.txt-block h3,
.txt-block h2 {
  margin-bottom: 26px;
}

h5.sm-title {
  margin-top: 20px;
  margin-bottom: 20px;
}

.txt-block .btn,
.txt-block .stores-badge {
  margin-top: 20px;
}

/*------------------------------------------*/
/*  Text Block Icon
/*------------------------------------------*/ 

.block-ico {
  position: relative;
  margin-left: 5px;
  margin-bottom: 35px;
  display: inline-block;
}

.block-ico-bkg {
  position: absolute;
  top: -25px;
  left: -15px;
  transform: rotate(-30deg);
}

/*------------------------------------------*/
/*  Text Block Icons list 
/*------------------------------------------*/ 

.txt-block-ico-list {
  margin-top: 40px;
}

.txt-block-ico-list li {
  width: auto !important;
  display: inline-block !important;
  vertical-align: top;
  clear: none !important;
  margin: 0 30px 0 0;
  padding: 0;
}

/*------------------------------------------*/
/*  Text Block Rating
/*------------------------------------------*/ 

.txt-block-rating {
  margin-top: 20px;
}

.stars-rating,
.stars-rating p {
  font-size: 1.05rem;
  margin-bottom: 0;
}

.txt-block-rating [class*="flaticon-"]:before, 
.txt-block-rating [class*="flaticon-"]:after { 
  font-size: 0.95rem; 
  line-height: 1;
  margin-left: 1px;  
} 

.stars-rating span {
  color: #ffc832;
} 

.stars-rating.grey-stars span {
  color: #757575;
} 

/*------------------------------------------*/
/*  IMG BLOCK IMAGE
/*------------------------------------------*/ 

.img-block {
  text-align: center;
}

/*------------------------------------------*/
/*  BORDER RADIUS SETTING
/*------------------------------------------*/ 

.radius-0 {
  -webkit-border-radius: 0px;
  -moz-border-radius: 0px;
  -o-border-radius: 0px;
  border-radius: 0px;
}

.radius-02 {
  -webkit-border-radius: 2px;
  -moz-border-radius: 2px;
  -o-border-radius: 2px;
  border-radius: 2px;
}

.radius-04 {
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  -o-border-radius: 4px;
  border-radius: 4px;
}

.radius-06 {
  -webkit-border-radius: 6px;
  -moz-border-radius: 6px;
  -o-border-radius: 6px;
  border-radius: 6px;
}

.radius-08 {
  -webkit-border-radius: 8px;
  -moz-border-radius: 8px;
  -o-border-radius: 8px;
  border-radius: 8px;
}




/* ==========================================================================
  03. PRELOAD ANIMATION
  ========================================================================== */

#loader-wrapper {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 99999999;
}

#loading {
  background-color: #fff;
  height: 100%;
  width: 100%;
  position: fixed;
  z-index: 99999999;
  margin-top: 0px;
  top: 0px;
}

.cssload-loader {
  position: absolute;
  left: 50%;
  top: 50%;
  margin-top: -35px;
  margin-left: -35px;
  width: 70px;
  height: 70px;
  border: 6px solid #007afc;
  animation: cssload-loader 3.9s infinite ease;
  -o-animation: cssload-loader 3.9s infinite ease;
  -ms-animation: cssload-loader 3.9s infinite ease;
  -webkit-animation: cssload-loader 3.9s infinite ease;
  -moz-animation: cssload-loader 3.9s infinite ease;
}

.cssload-loader-inner {
  vertical-align: top;
  display: inline-block;
  width: 100%;
  background-color: #007afc;
  animation: cssload-loader-inner 3.9s infinite ease-in;
  -o-animation: cssload-loader-inner 3.9s infinite ease-in;
  -ms-animation: cssload-loader-inner 3.9s infinite ease-in;
  -webkit-animation: cssload-loader-inner 3.9s infinite ease-in;
  -moz-animation: cssload-loader-inner 3.9s infinite ease-in;
}


@keyframes cssload-loader {
  0% {
    transform: rotate(0deg);
  }
  
  25% {
    transform: rotate(180deg);
  }
  
  50% {
    transform: rotate(180deg);
  }
  
  75% {
    transform: rotate(360deg);
  }
  
  100% {
    transform: rotate(360deg);
  }
}

@-o-keyframes cssload-loader {
  0% {
    transform: rotate(0deg);
  }
  
  25% {
    transform: rotate(180deg);
  }
  
  50% {
    transform: rotate(180deg);
  }
  
  75% {
    transform: rotate(360deg);
  }
  
  100% {
    transform: rotate(360deg);
  }
}

@-ms-keyframes cssload-loader {
  0% {
    transform: rotate(0deg);
  }
  
  25% {
    transform: rotate(180deg);
  }
  
  50% {
    transform: rotate(180deg);
  }
  
  75% {
    transform: rotate(360deg);
  }
  
  100% {
    transform: rotate(360deg);
  }
}

@-webkit-keyframes cssload-loader {
  0% {
    transform: rotate(0deg);
  }
  
  25% {
    transform: rotate(180deg);
  }
  
  50% {
    transform: rotate(180deg);
  }
  
  75% {
    transform: rotate(360deg);
  }
  
  100% {
    transform: rotate(360deg);
  }
}

@-moz-keyframes cssload-loader {
  0% {
    transform: rotate(0deg);
  }
  
  25% {
    transform: rotate(180deg);
  }
  
  50% {
    transform: rotate(180deg);
  }
  
  75% {
    transform: rotate(360deg);
  }
  
  100% {
    transform: rotate(360deg);
  }
}

@keyframes cssload-loader-inner {
  0% {
    height: 0%;
  }
  
  25% {
    height: 0%;
  }
  
  50% {
    height: 100%;
  }
  
  75% {
    height: 100%;
  }
  
  100% {
    height: 0%;
  }
}

@-o-keyframes cssload-loader-inner {
  0% {
    height: 0%;
  }
  
  25% {
    height: 0%;
  }
  
  50% {
    height: 100%;
  }
  
  75% {
    height: 100%;
  }
  
  100% {
    height: 0%;
  }
}

@-ms-keyframes cssload-loader-inner {
  0% {
    height: 0%;
  }
  
  25% {
    height: 0%;
  }
  
  50% {
    height: 100%;
  }
  
  75% {
    height: 100%;
  }
  
  100% {
    height: 0%;
  }
}

@-webkit-keyframes cssload-loader-inner {
  0% {
    height: 0%;
  }
  
  25% {
    height: 0%;
  }
  
  50% {
    height: 100%;
  }
  
  75% {
    height: 100%;
  }
  
  100% {
    height: 0%;
  }
}

@-moz-keyframes cssload-loader-inner {
  0% {
    height: 0%;
  }
  
  25% {
    height: 0%;
  }
  
  50% {
    height: 100%;
  }
  
  75% {
    height: 100%;
  }
  
  100% {
    height: 0%;
  }
}




/* ==========================================================================
  04. HEADER & NAVIGATION
  =========================================================================== */

#header {
  width: 100%;
  display: block;
  padding-top: 0px;
}

.header-wrapper {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1030;
}

.headerwp {
  max-width: 1140px;
  margin: 0 auto;
  padding: 0;
}

.posrlt {
  position: relative;
}

#header.hiddden-header {
  display: none;
}

.hidden-nav .wsmainfull {
  margin-top: -100px;
  -webkit-box-shadow: 0 2px 3px rgba(96, 96, 96, .1);
  -moz-box-shadow: 0 2px 3px rgba(96, 96, 96, .1);
  box-shadow: 0 2px 3px rgba(96, 96, 96, .1);
  -webkit-transition: all 400ms ease-in-out;
  -moz-transition: all 400ms ease-in-out;
  -o-transition: all 400ms ease-in-out;
  -ms-transition: all 400ms ease-in-out;
  transition: all 400ms ease-in-out;
}

.hidden-nav .wsmainfull.scroll {
  margin-top: 0;
}

/*------------------------------------------*/
/*  HEADER LOGO
/*------------------------------------------*/

.desktoplogo img {
  width: auto;
  max-width: inherit;
  max-height: 38px;
}

/*------------------------------------------*/
/*   NAVIGATION MENU
/*------------------------------------------*/

.wsmainfull {
  width: 100%;
  height: auto;
  z-index: 1031;
  -webkit-transition: all 450ms ease-in-out;
  -moz-transition: all 450ms ease-in-out;
  -o-transition: all 450ms ease-in-out;
  -ms-transition: all 450ms ease-in-out;
  transition: all 450ms ease-in-out; 
}

.tra-menu .wsmainfull {
  background-color: transparent!important;
  padding: 20px 0;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
}

.white-menu .wsmainfull {
  background-color: #fff!important;
  padding: 10px 0;
  -webkit-box-shadow: 0 2px 3px rgba(96, 96, 96, .1);
  -moz-box-shadow: 0 2px 3px rgba(96, 96, 96, .1);
  box-shadow: 0 2px 3px rgba(96, 96, 96, .1);
}

.dark-menu .wsmainfull {
  background-color: #222227!important;
  padding: 10px 0;
  -webkit-box-shadow: 0 2px 3px rgba(96, 96, 96, .1);
  -moz-box-shadow: 0 2px 3px rgba(96, 96, 96, .1);
  box-shadow: 0 2px 3px rgba(96, 96, 96, .1);
}

/*------------------------------------------*/
/*  HEADER LINK
/*------------------------------------------*/

.wsmenu > .wsmenu-list > li > a {
  display: block;
  font-family: "Google Sans", Helvetica, Arial, sans-serif;
  font-size: 17px;
  font-weight: 500;
  letter-spacing: 0;
  margin: 0 7px;
  padding: 10px 15px;
  line-height: 50px;
  text-decoration: none;  
}

.navbar-dark .wsmenu > .wsmenu-list > li > a {
  color: #463853;
}

.navbar-light .wsmenu > .wsmenu-list > li > a {
  color: #fff;
}

.wsmenu > .wsmenu-list > li > a.last-link {
  padding: 10px 0px;
  margin: 0 0 0 7px;
}

.wsmenu > .wsmenu-list > li > a .wsarrow:after {
  border-left: 4px solid rgba(0, 0, 0, 0);
  border-right: 4px solid rgba(0, 0, 0, 0);
  border-top: 4px solid;
  content: "";
  float: right;
  right: 0;
  height: 0;
  margin: 0 0 0 14px;
  position: absolute;
  text-align: right;
  top: 33px;
  width: 0;
}

/*------------------------------------------*/
/*  HEADER BUTTONS
/*------------------------------------------*/

.wsmenu > .wsmenu-list > li a.btn {
  font-size: 16.5px;
  line-height: 30px;
  font-weight: 500;
  text-transform: none;
  letter-spacing: 0;
  margin-top: 14px;
  margin-left: 12px;
  padding: 5px 30px;
}

.wsmenu > .wsmenu-list > li a.header-store {
  margin: 8px 0 0 12px;
  padding: 0;
}

/*------------------------------------------*/
/*  HEADER SOCIAL LINKS  
/*------------------------------------------*/

.header-socials {
  margin: 0;
  display: inline-block; 
  text-align: center;
}

.header-socials span {
  float: left;
  width: auto !important;
  display: inline-block !important;
  vertical-align: top;
  clear: none !important;
  margin: 0;
}

.header-socials a {
  display: block;
  line-height: 70px!important;   
  margin-left: 16px;
}

.header-socials a span {
  position: relative;
  top: 2px;
}

.header-socials.ico-20 [class*="flaticon-"]:before, 
.header-socials.ico-20 [class*="flaticon-"]:after { font-size: 1.325rem; } 

/*------------------------------------------*/
/*  HEADER SUBMENU
/*------------------------------------------*/

.wsmenu > .wsmenu-list > li > ul.sub-menu {
  padding: 12px 20px;
  width: auto;
  min-width: 200px;
  -webkit-border-radius: 2px;
  -moz-border-radius: 2px;
  -o-border-radius: 2px;
}

.wsmenu > .wsmenu-list > li > ul.sub-menu > li > a {
  font-family: "Google Sans", Helvetica, Arial, sans-serif;
  color: #656565;
  font-size: 16px;
  font-weight: 500;
  padding: 8px 0;
  transition: all 0.3s ease-in-out;
}

.wsmenu > .wsmenu-list > li > ul.sub-menu > li > a:hover {
  padding: 8px 0 8px 8px;
}

/*------------------------------------------*/
/*   HALFMENU
/*------------------------------------------*/

.wsmenu > .wsmenu-list > li > .wsmegamenu.halfmenu {
  width: 36%;
  padding: 15px 10px;
  -webkit-border-radius: 2px;
  -moz-border-radius: 2px;
  -o-border-radius: 2px;
}

.wsmenu > .wsmenu-list > li > .wsmegamenu .link-list li {
  border-bottom: none;
}

.wsmenu > .wsmenu-list > li > .wsmegamenu.halfmenu ul.link-list > li > a {
  font-family: "Google Sans", Helvetica, Arial, sans-serif;
  color: #656565;
  font-size: 16px;
  font-weight: 500;
  padding: 8px 0;
  transition: all 0.3s ease-in-out;
}

.wsmenu > .wsmenu-list > li > .wsmegamenu.halfmenu ul.link-list > li > a:hover {
  padding: 8px 0 8px 8px;
}

/*------------------------------------------*/
/*  NAVBAR HOVER
/*------------------------------------------*/

.wsmenu > .wsmenu-list.nav-blue-hover > li > ul.sub-menu > li > a:hover,
.wsmenu > .wsmenu-list.nav-blue-hover > li > .wsmegamenu.halfmenu ul.link-list > li > a:hover  {
  color: #007afc!important;
}

.wsmenu > .wsmenu-list.nav-purple-hover > li > ul.sub-menu > li > a:hover,
.wsmenu > .wsmenu-list.nav-purple-hover > li > .wsmegamenu.halfmenu ul.link-list > li > a:hover  {
  color: #454c9d!important;
}

.wsmenu > .wsmenu-list.nav-rose-hover > li > ul.sub-menu > li > a:hover,
.wsmenu > .wsmenu-list.nav-rose-hover > li > .wsmegamenu.halfmenu ul.link-list > li > a:hover  {
  color: #ff495a!important;
}

.wsmenu > .wsmenu-list.nav-skyblue-hover > li > ul.sub-menu > li > a:hover,
.wsmenu > .wsmenu-list.nav-skyblue-hover > li > .wsmegamenu.halfmenu ul.link-list > li > a:hover {
  color: #009ddc!important;
}

.wsmenu > .wsmenu-list.nav-violet-hover > li > ul.sub-menu > li > a:hover,
.wsmenu > .wsmenu-list.nav-violet-hover > li > .wsmegamenu.halfmenu ul.link-list > li > a:hover  {
  color: #8e51c7!important;
}

.wsmenu > .wsmenu-list.nav-yellow-hover > li > ul.sub-menu > li > a:hover,
.wsmenu > .wsmenu-list.nav-yellow-hover > li > .wsmegamenu.halfmenu ul.link-list > li > a:hover  {
  color: #fdb827!important;
}

/*------------------------------------------*/
/*  NAVBAR SCROLL
/*------------------------------------------*/

.tra-menu .wsmainfull.scroll,
.white-menu .wsmainfull.scroll,
.dark-menu.dark-scroll .wsmainfull.scroll {
  background-color: #fff!important;
  padding: 3px 0;
  -webkit-box-shadow: 0 2px 3px rgba(96, 96, 96, .1);
  -moz-box-shadow: 0 2px 3px rgba(96, 96, 96, .1);
  box-shadow: 0 2px 3px rgba(96, 96, 96, .1);
}

.scroll .white-color.header-socials span {
  color: #463853!important;
}

/*------------------------------------------*/
/*  Navigation Menu
/*------------------------------------------*/

.tra-menu.navbar-light .scroll .wsmenu > .wsmenu-list > li a {
  color: #463853;
}

.tra-menu.navbar-light.dark-scroll .scroll .wsmenu > .wsmenu-list > li a,
.tra-menu.navbar-dark.dark-scroll .scroll .wsmenu > .wsmenu-list > li a {
  color: #fff;
}

.tra-menu.navbar-light.dark-scroll .scroll .wsmenu > .wsmenu-list .sub-menu li a,
.tra-menu.navbar-dark.dark-scroll .scroll .wsmenu > .wsmenu-list .sub-menu li a,
.tra-menu.navbar-light.dark-scroll .scroll .wsmenu > .wsmenu-list li .wsmegamenu a,
.tra-menu.navbar-dark.dark-scroll .scroll .wsmenu > .wsmenu-list li .wsmegamenu a {
  color: #666;
}

/*------------------------------------------*/
/*  Logo Image
/*------------------------------------------*/

.logo-white, 
.logo-black {
  display: block;
}

.navbar-light .logo-black,
.navbar-dark .logo-white,
.tra-menu.navbar-light .scroll .logo-white,
.tra-menu.navbar-light.dark-scroll .scroll .logo-black,
.tra-menu.navbar-dark.dark-scroll .scroll .logo-black {
  display: none;
}

.tra-menu.navbar-light .scroll .logo-black,
.tra-menu.navbar-light.dark-scroll .scroll .logo-white,
.tra-menu.navbar-dark.dark-scroll .scroll .logo-white {
  display: block;
}




/* ==========================================================================
  05. HERO
  =========================================================================== */

.hero-section .container {
  position: relative;
  z-index: 3;
}

#hero-1 {  
  padding-top: 100px;
  padding-bottom: 50px;
  position: relative;
  z-index: 3;
  overflow: hidden;
}

#hero-2 {
  background: linear-gradient(rgba(0, 0, 0, 0.2) 0%, rgba(0, 0, 0, 0) 100%), linear-gradient(rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.2) 100%), 
  linear-gradient(209.21deg, rgb(65, 108, 226) 13.57%, rgb(86, 215, 222) 98.38%);
  padding-top: 140px;
  padding-bottom: 60px;
  position: relative;
  z-index: 3;
}

#hero-2 .white-overlay-wave {
  z-index: 999;
}

#hero-3 {  
  background-image: url(./images/hero-3.jpg);
  padding-top: 150px;
  padding-bottom: 60px;
  position: relative;
  z-index: 3;
}

.hero-4-txt {
  background-image: url(./images/hero-4.jpg);  
  padding-top: 160px;
  padding-bottom: 400px;
}

#hero-5 {
 padding-top: 120px;
 padding-bottom: 80px;
}

#hero-5:after {
  position: absolute;
  content: '';
  width: 50%;
  height: 100%;
  background-color: #e6e7f9;
  top: 0;
  left: 50%;
  z-index: -1;
  -webkit-border-radius: 0 0 0 8px;
  -moz-border-radius: 0 0 0 8px;
  -o-border-radius: 0 0 0 8px;
  border-radius: 0 0 0 8px;
}

#hero-6 {
  background-color: #319cfb;
  padding-top: 130px;
  padding-bottom: 30px;
}

#hero-7 {
  background-image: url(./images/hero-7.jpg);
  background-position: bottom center;
  padding-top: 150px;
  padding-bottom: 40px;
}

#hero-8 {
  background-image: url(./images/hero-8.jpg);
  margin-top: 75px;
  padding-bottom: 35px;
  margin-bottom: 50px;
}

#hero-9 {
  background-color: #9dbcd4;
  padding-top: 130px;
  position: relative;
  z-index: 3;
}

#hero-10 {
  background-image: url(./images/background.jpg);
  padding-top: 110px;
  padding-bottom: 40px;
  margin-bottom: 50px;
}

#hero-11 {
  background-color: #5e22d0;
  padding-top: 160px;
  padding-bottom: 180px;
  position: relative;
  z-index: 3;
}

#hero-12 {
  background-image: url(./images/hero-12.jpg);
  padding-top: 130px;
  position: relative;
}

.img-ipad {
  max-width: 100%;
  height: 100%;
}

/*------------------------------------------*/
/*  HERO TEXT
/*------------------------------------------*/

.hero-1-txt {
  margin-top: -50px;
}

.hero-5-btns {
  margin-top: -60px;
}

.hero-6-txt {
  margin-top: 50px;
}

.hero-8-txt {
  margin-top: 35px;
}

.hero-9-txt {
  margin-top: -30px;
  margin-left: -35px;
  padding-right: 30px;
}

.hero-10-txt {
  margin-left: -15px;
  padding-right: 25px;
}

/*------------------------------------------*/
/*  HERO APP LOGO
/*------------------------------------------*/

.hero-app-logo {
  margin-bottom: 30px;
}

#hero-10 .hero-app-logo {
  margin-bottom: 35px;
}

.hero-app-logo img {
  width: auto;
  max-width: inherit;
  max-height: 95px;
}

/*------------------------------------------*/
/*  Hero Headers
/*------------------------------------------*/ 

.hero-1-txt h2 {
  font-size: 3.6875rem;
  line-height: 1.2;
  margin-bottom: 25px;
}

.hero-2-txt h2 {
  margin-bottom: 25px;
}

.hero-3-txt h5 {
  font-weight: 400;
  margin-bottom: 35px;
}

.hero-3-txt h2 {
  margin-bottom: 25px;
}

.hero-4-txt h2 {
  padding: 0 5%;
  margin-bottom: 30px;
}

.hero-5-txt h3 {
  line-height: 1.3;
  margin-bottom: 20px;
}

.hero-5-btns h3 {
  margin-bottom: 30px;
}

.hero-6-txt h2 {
  font-size: 5.5rem;
  line-height: 1.15;
  font-weight: 700;
  letter-spacing: -0.5px;
  padding-right: 3%;
  margin-bottom: 25px;
}

.hero-7-txt h2 {
  margin-bottom: 25px;
}

.hero-8-txt h2 {
  margin-bottom: 5px;
}

.hero-8-txt h4 {
  margin-bottom: 18px;
}

.hero-8-txt h5 {
  font-weight: 300;
  margin-bottom: 50px;
}

.hero-9-txt h4 {
  font-weight: 400;
  margin-bottom: 20px;
}

.hero-9-txt h2 {
  letter-spacing: -1px;
  margin-bottom: 30px;
}

.hero-10-txt h2 {
  margin-bottom: 45px;
}

.hero-11-txt h2 {
  padding: 0 12%;
  margin-bottom: 25px;
}

.hero-11-txt h2 span {
  font-weight: 700;
}

.hero-12-txt h2 {
  line-height: 1;
  margin-bottom: 20px;
}

.hero-12-txt h4 {
  font-weight: 300;
  line-height: 1.35;
  padding: 0 5%;
  margin-bottom: 35px;
}

/*------------------------------------------*/
/*  Hero Paragraphs
/*------------------------------------------*/

.hero-1-txt p {
  font-size: 1.3rem;
  margin-bottom: 35px;
}

.hero-2-txt p {
  margin-bottom: 35px;
}

.hero-3-txt p {
  padding-right: 4%;
  margin-bottom: 0;
}

.hero-5-txt p { 
  padding-bottom: 0;
}

.hero-6-txt p {
  font-size: 1.3rem;
  padding-right: 10%;
  margin-bottom: 35px;
}

.hero-7-txt p {
  margin-bottom: 35px;
}

.hero-11-txt p {
  font-size: 1.35rem;
  padding: 0 15%;
  margin-bottom: 35px;
}

/*------------------------------------------*/
/*  HERO OS
/*------------------------------------------*/

.hero-section .os-version { 
  font-size: 1.08rem; 
}

/*------------------------------------------*/
/*  HERO FORM
/*------------------------------------------*/

#hero-3 .request-form {
  margin: 30px 30px 30px 0;
}

#hero-9 .request-form {
  margin: 40px 20px 30px 0;
}

/*------------------------------------------*/
/*  Hero Request Form Input
/*------------------------------------------*/

.request-form .form-control {
  height: 56px;
  background-color: #fff;
  border: 1px solid #ccc;
  color: #666;
  font-size: 18px;
  font-weight: 400;
  padding: 0 20px;
  box-shadow: none;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  -o-border-radius: 4px;
  border-radius: 4px; 
  -webkit-transition: all 450ms ease-in-out;
  -moz-transition: all 450ms ease-in-out;
  -o-transition: all 450ms ease-in-out;
  -ms-transition: all 450ms ease-in-out;
  transition: all 450ms ease-in-out;  
}

.request-form .input-group > .form-control:not(:last-child) {
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
}

/*------------------------------------------*/
/*  Hero Request Form Placeholder
/*------------------------------------------*/

.request-form .form-control::-moz-placeholder { color: #998da0; } 
.request-form .form-control:-ms-input-placeholder { color: #998da0; }
.request-form .form-control::-webkit-input-placeholder { color: #998da0; }

/*------------------------------------------*/
/*  Hero Request Form Focus
/*------------------------------------------*/

.request-form .form-control:focus {
  background-color: #f5f6f7;
  border-color: #ccc;
  outline: 0;
  box-shadow: none;
}

/*------------------------------------------*/
/*  Hero Request Form Button
/*------------------------------------------*/

.request-form .btn {
  height: 56px;
  font-size: 18px;
  padding: 13px 40px;
  margin-left: 10px;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  -o-border-radius: 4px;
  border-radius: 4px;
}

/*------------------------------------------*/
/*  Hero Request Form Message
/*------------------------------------------*/

.request-form-msg {
  width: 100%!important;
  display: block;
  margin-top: 20px;
  margin-bottom: 15px;
}

.request-form .loading {
  color: #00b2e4;
  font-size: 17px;
  line-height: 1;
  font-weight: 500;
}

/*------------------------------------------*/
/*  HERO IMAGE
/*------------------------------------------*/

.hero-2-img {
  margin-left: -10%;
}

.hero-4-img img {
  margin-top: -350px;
}

.hero-6-img {
  text-align: center;
  margin: 0 0 -100px -45%;
  position: relative;
  z-index: 20;
}

.hero-6-img img {
  position: relative;
  max-width: none;
  display: inline-block;
}

.hero-8-img {
  margin-bottom: -100px;
}

.hero-9-img {
  margin: 0 40px -60px -35px;
}

.hero-10-img {
  text-align: center;
  margin-bottom: -120px;
}

.hero-11-img {
  text-align: center;
  margin-top: 40px;
  margin-bottom: -200px;
}




/* ==========================================================================
  06. ABOUT
  =========================================================================== */ 

.about-2-txt h5 {
  margin-bottom: 20px;
}   

#about-1 p {
  font-size: 1.75rem;
  margin-bottom: 0;
}




/* ==========================================================================
  07. FEATURES
  =========================================================================== */  

#features-13.aliceblue_shape:after,
#features-13.whitesmoke_shape:after {
  position: absolute;
  content: '';
  width: 100%;
  height: 80%;
  background-color: #f5f6f7;
  top: 10%;
  left: 0;
  z-index: -1;
}

#features-13.aliceblue_hape:after {
  background-color: #f2f2fa;
}

#features-3 .col-md-6 {
  padding-left: 10px;
  padding-right: 10px;
}

.fbox-4 .col-lg-8 { 
  padding-right: 8px; 
}

/*------------------------------------------*/
/*  FEATURES BOX WRAPPER
/*------------------------------------------*/ 

.fbox-7-wrapper {
  padding: 0 12%;
}

.fbox-11-wrapper {
  text-align: center;
  padding: 80px 60px 40px;
  -webkit-border-radius: 8px;
  -moz-border-radius: 8px;
  border-radius: 8px;
  -webkit-box-shadow: 0px 5px 10px 0 rgba(10, 10, 10, .05);
  box-shadow: 0px 5px 10px 0 rgba(10, 10, 10, .05);
}

.fbox-12-wrapper {
  padding: 0 10%;
}

/*------------------------------------------*/
/*  FEATURE BOX
/*------------------------------------------*/ 

.fbox-2,
.fbox-3,
.fbox-4,
.fbox-13 {
  padding: 50px 35px 35px;
  -webkit-border-radius: 20px 20px 0 20px;
  -moz-border-radius: 20px 20px 0 20px;
  border-radius: 20px 20px 0 20px;
  -webkit-transition: all 300ms ease-in-out;
  -moz-transition: all 300ms ease-in-out;
  -o-transition: all 300ms ease-in-out;
  -ms-transition: all 300ms ease-in-out;
  transition: all 300ms ease-in-out;  
} 

.fbox-3 {
  padding: 45px 25px;
} 

.fbox-4 {
  padding: 50px 36px;
  -webkit-border-radius: 8px;
  -moz-border-radius: 8px;
  border-radius: 8px;
} 

.fbox-12 {
  text-align: center;
  border: 1px solid #ddd;
  padding: 50px 55px;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  border-radius: 10px;
} 

.bg_whitesmoke .fbox-12 {
  border-color: transparent;
  -webkit-box-shadow: 0px 5px 10px 0 rgba(10, 10, 10, .05);
  box-shadow: 0px 5px 10px 0 rgba(10, 10, 10, .05);
}

.fbox-13 {
  padding: 45px 25px 35px;
  -webkit-border-radius: 20px;
  -moz-border-radius: 20px;
  border-radius: 20px;
} 

.fbox-2.bg_white,
.fbox-3.bg_white,
.fbox-4.bg_white,
.fbox-13.bg_white {
  background-color: #fff;
  border: 2px solid #e4e5e9;
}

.fbox-2.bg_white:hover,
.fbox-3.bg_white:hover,
.fbox-4.bg_white:hover,
.fbox-13.bg_white:hover {
  border-color: #fafafa;
  -webkit-box-shadow: 0 0 10px rgba(0, 0, 0, .1);
  -moz-box-shadow: 0 0 10px rgba(0, 0, 0, .1);
  box-shadow: 0 0 10px rgba(0, 0, 0, .1);
}

.fbox-2.bg_aliceblue,
.fbox-3.bg_aliceblue,
.fbox-4.bg_aliceblue,
.fbox-13.bg_aliceblue,
.fbox-2.bg_whitesmoke,
.fbox-3.bg_whitesmoke,
.fbox-4.bg_whitesmoke,
.fbox-13.bg_whitesmoke {
  border-color: transparent;
  -webkit-box-shadow: 0px 5px 10px 0 rgba(10, 10, 10, .05);
  box-shadow: 0px 5px 10px 0 rgba(10, 10, 10, .05);
} 

#features-13.aliceblue_shape .fbox-13,
#features-13.whitesmoke_shape .fbox-13,
.bg_aliceblue .fbox-2, .bg_whitesmoke .fbox-2,
.bg_aliceblue .fbox-3, .bg_whitesmoke .fbox-3,
.bg_aliceblue .fbox-4, .bg_whitesmoke .fbox-4,
.bg_aliceblue .fbox-13, .bg_whitesmoke .fbox-13 {
  background-color: #fff;
  border: 2px solid #f8f8f9;
  -webkit-box-shadow: 0 0 10px rgba(0, 0, 0, .1);
  -moz-box-shadow: 0 0 10px rgba(0, 0, 0, .1);
  box-shadow: 0 0 10px rgba(0, 0, 0, .1);
}

/*------------------------------------------*/
/*  FEATURE BOX TEXT
/*------------------------------------------*/

.fbox-txt {
  overflow: hidden;
  padding-left: 24px;
}

.fbox-6-title {
  margin-bottom: 8px;
}

/*------------------------------------------*/
/*  FEATURE BOX TYPOGRAPHY
/*------------------------------------------*/ 

.fbox-1 h5,
.fbox-3 h5,
.fbox-12 h5 {
  margin-top: 30px;
  margin-bottom: 12px;
}

.fbox-2 h5 {
  margin-top: 40px;
  margin-bottom: 12px;
}

.fbox-4 h5,
.fbox-5 h5,
.fbox-9 h5 {
  margin-bottom: 12px;
}

.fbox-6 h5 {  
  line-height: 2.5rem!important;
  margin-bottom: 0;
}

.fbox-7 h5,
.fbox-8 h5 {
  margin-top: 25px;
  margin-bottom: 0;
}

.fbox-10 h5 {
  margin-top: 20px;
  margin-bottom: 12px;
}

.fbox-11 h5,
.fbox-13 h5 {
  margin-top: 25px;
  margin-bottom: 12px;
}

.fbox-1 p,
.fbox-3 p,
.fbox-4 p,
.fbox-5 p,
.fbox-9 p,
.fbox-11 p,
.fbox-12 p,
.fbox-13 p {
  line-height: 1.5;
  margin-bottom: 0;
}

/*------------------------------------------*/
/*  FEATURE BOX ICON
/*------------------------------------------*/ 

.fbox-1-ico {
  position: relative;
  display: inline-block;
}

.fbox-ico {     
  float: left;
  text-align: center;
  margin-top: 2px;
}

.fbox-4-ico {
  position: relative;
  opacity: .6;
}

.fbox-6-title span,
.fbox-6-title img {
  float: left;
  line-height: 1!important;
  margin-right: 15px; 
}

.fbox-11-ico {
  width: 105px;
  height: 105px;
  line-height: 105px!important;
  text-align: center;
  -webkit-border-radius: 100%;
  -moz-border-radius: 100%;
  -o-border-radius: 100%;
  border-radius: 100%;
  margin: 0 auto;
}

.fbox-11-ico.ico-60 [class*="flaticon-"]:before, 
.fbox-11-ico.ico-60 [class*="flaticon-"]:after { line-height: 105px!important; } 

.fbox-11-ico.bg_stateblue { background-color: rgba(137, 145, 209, .5); }

/*------------------------------------------*/
/*  FEATURES DOWNLOAD BUTTON
/*------------------------------------------*/ 

.features-download-btn {
  text-align: center;
  margin-top: 20px;
  margin-bottom: 40px;
} 




/* ==========================================================================
  08. CONTENT
  =========================================================================== */

#content-4 .container {
  overflow: hidden;
}

#content-7:after,
#content-8:after,
#content-9:after {
  position: absolute;
  content: '';
  width: 40%;
  height: 90%;
  background-color: #f5f6f7;
  top: 5%;
  left: 0;
  z-index: -1;
  -webkit-border-radius: 0 8px 8px 0;
  -moz-border-radius: 0 8px 8px 0;
  -o-border-radius: 0 8px 8px 0;
  border-radius: 0 8px 8px 0;
}

#content-7.aliceblue:after,
#content-8.aliceblue:after,
#content-9.aliceblue:after {
  background-color: #f2f2fa;
}

#content-8:after {
  width: 76%;
  height: 80%;
  top: 10%;
  right: 24%;
  -webkit-border-radius: 0 8px 8px 0;
  -moz-border-radius: 0 8px 8px 0;
  -o-border-radius: 0 8px 8px 0;
  border-radius: 0 8px 8px 0;
}

#content-9:after {
  width: 76%;
  height: 80%;
  top: 10%;
  left: 24%;
  -webkit-border-radius: 8px 0 0 8px;
  -moz-border-radius: 8px 0 0 8px;
  -o-border-radius: 8px 0 0 8px;
  border-radius: 8px 0 0 8px;
}

#content-10 {
  margin-bottom: 80px;
}

.content-12-wrapper {
  padding: 80px 30px 0;
  -webkit-border-radius: 20px;
  -moz-border-radius: 20px;
  border-radius: 20px;  
}

.content-11-btn {
  text-align: center;
}

.content-11-btn .btn {
  margin-bottom: 5px;
}

#content-14.aliceblue_shape,
#content-14.whitesmoke_shape {
  position: relative;
  padding-top: 100px;
}

#content-14.aliceblue_shape:after,
#content-14.whitesmoke_shape:after {
  position: absolute;
  content: '';
  width: 100%;
  height: 90%;
  background-color: #f7f7f7;
  top: 0;
  left: 0;
  z-index: -1;
}

#content-14.aliceblue_shape:after {
  background-color: #f2f2fa;
}

/*------------------------------------------*/
/*  CONTENT BOX
/*------------------------------------------*/

.cbox-txt {
  overflow: hidden;
  padding-left: 26px;
}

.cbox-ico {     
  float: left;
  text-align: center;
  margin-top: 2px;
  -webkit-transition: all 450ms ease-in-out;
  -moz-transition: all 450ms ease-in-out;
  -o-transition: all 450ms ease-in-out;
  -ms-transition: all 450ms ease-in-out;
  transition: all 450ms ease-in-out;  
}

.cbox-txt h5 {
  margin-bottom: 12px;
  -webkit-transition: all 450ms ease-in-out;
  -moz-transition: all 450ms ease-in-out;
  -o-transition: all 450ms ease-in-out;
  -ms-transition: all 450ms ease-in-out;
  transition: all 450ms ease-in-out;  
}

.cbox-txt p {
  margin-bottom: 0;
}

/*------------------------------------------*/
/*  CONTENT IMAGE
/*------------------------------------------*/

.content-5-img,
.content-6-img {
  text-align: center;
}

.content-5-img {
  margin-bottom: -80px;
}

.content-6-img {
  margin-top: -80px;
}

.content-10-img {
  text-align: center;  
  margin-bottom: -80px;
}

.content-12-img {
  text-align: center;
  margin-right: -80px;
  margin-bottom: -60px;
}

.content-13-img {
  text-align: center;
  margin-left: 30px;
  margin-bottom: 40px;
}

.content-13-img img {
  max-width: none;
  display: inline-block;
}




/* ==========================================================================
  09. PROCESS
  ========================================================================== */

.pbox-1 {
  text-align: center;
  margin-bottom: 40px;
}

/*------------------------------------------*/
/*  PROCESS BOX TYPOGRAPHY
/*------------------------------------------*/

.pbox-1 h5,
.pbox-2 h5 {
  margin-top: 30px;
  margin-bottom: 12px;
}

.pbox-1 p,
.pbox-2 p {
  margin-bottom: 0;
}

/*------------------------------------------*/
/*  PROCESS BOX ICON
/*------------------------------------------*/

.pbox-ico {
  width: 110px;
  height: 110px;
  line-height: 110px!important;
  text-align: center;
  -webkit-border-radius: 100%;
  -moz-border-radius: 100%;
  -o-border-radius: 100%;
  border-radius: 100%;
  margin: 0 auto;
}

.pbox-ico.ico-60 [class*="flaticon-"]:before,
.pbox-ico.ico-60 [class*="flaticon-"]:after { 
  line-height: 110px!important;
} 

.pbox-ico::after {
  content: "";
  width: 30%;
  position: absolute;
  border-bottom: 2px dashed #999;
  top: 22%;
  left: 100%;
  transform: translateX(-50%);
}

#step-1-4 .pbox-ico::after {
  display: none;
}

/*------------------------------------------*/
/*  STEP PROCESS BAR
/*------------------------------------------*/

.processbar {
  counter-reset: step;
}

.processbar li {
  float: left;
  position: relative;
  text-align: center;
  z-index: 1;
}

.processbar li:before {
  width: 46px;
  height: 46px;
  color: #fff;
  font-size: 1.5rem;
  font-family: "Google Sans", "Roboto", sans-serif;
  font-weight: 700;
  content: counter(step);
  counter-increment: step;
  line-height: 46px;
  background-color: #007afc;
  display: block;
  text-align: center;
  margin: 0 auto 10px auto;
  border-radius: 50%;
  text-align: center;
}

.processbar li:after {
  width: 100%;
  height: 2px;
  content: '';
  position: absolute;
  background-color: #007afc;
  top: 22px;
  left: -49%;
  z-index : -1;
} 

.processbar li:first-child:after {
  content: none;
}

.process-green.processbar li:after, 
.process-green.processbar li:before {
  background-color: #61bb46;
}

.process-skyblue.processbar li:after, 
.process-skyblue.processbar li:before {
  background-color: #009ddc;
}

.process-violet.processbar li:after, 
.process-violet.processbar li:before {
  background-color: #8e51c7;
}

.process-maroon.processbar li:after, 
.process-maroon.processbar li:before {
  background-color: #821E0D;
}

/*------------------------------------------*/
/*  PROCESS BUTTON
/*------------------------------------------*/ 

.process-btn {
  text-align: center;
  margin-top: 30px;
  margin-bottom: 40px;
}




/* ==========================================================================
  10. VIDEO
  =========================================================================== */

#video-1.bg_color {
  padding-top: 60px;
  padding-bottom: 60px;
}  

#video-2,
#video-3 {
  padding-top: 100px;
  margin-bottom: 170px;
}

/*------------------------------------------*/
/*  VIDEO TEXT
/*------------------------------------------*/

.video-1-txt h3 {
  margin-bottom: 15px;
}

.video-2-txt h2,
.video-3-txt h2 {
  margin-bottom: 20px;
}

.video-1-txt p {
  margin-bottom: 0;
}

.video-2-txt p,
.video-3-txt p {
  padding: 0 8%;
  margin-bottom: 0;
}

/*------------------------------------------*/
/*  VIDEO LINK
/*------------------------------------------*/

#video-1 .video-preview img,
#video-2 .video-preview img,
#video-3 .video-preview img {
  -webkit-border-radius: 12px;
  -moz-border-radius: 12px;
  border-radius: 12px;
}

.video-2-link,
.video-3-link {
  margin-bottom: -170px;
}



/* ==========================================================================
  11. STATISTIC
  =========================================================================== */

#statistic-1 .statistic-block {
  background-color: #f8f8fb;
  padding: 50px 25px;
  -webkit-border-radius: 8px;
  -moz-border-radius: 8px;
  -o-border-radius: 8px;
  border-radius: 8px;
  -webkit-box-shadow: 0px 5px 10px 0 rgba(10, 10, 10, .05);
  box-shadow: 0px 5px 10px 0 rgba(10, 10, 10, .05);
}

#statistic-1.bg_whitesmoke .statistic-block,
#statistic-1.bg_aliceblue .statistic-block {
  background-color: #fff;
}

/*------------------------------------------*/
/*  STATISTIC BLOCK
/*------------------------------------------*/

h2.statistic-number {
  line-height: 1; 
  letter-spacing: -0.5px;
  margin-top: 25px;
  margin-bottom: 5px;
}

#statistic-2 h2.statistic-number {
  margin-top: 0px;
}

.statistic-block h5 {
  line-height: 1;
  margin-top: 15px;
  margin-bottom: 0;
}

#statistic-2 .statistic-block h5 {
  margin-top: 10px;
}




/* ==========================================================================
  12. TESTIMONIALS
  =========================================================================== */

#reviews-1.aliceblue_shape,
#reviews-1.whitesmoke_shape,
#reviews-2 {
  position: relative;
  padding-top: 70px;
  padding-bottom: 60px;
}

#reviews-1.aliceblue_shape:after,
#reviews-1.whitesmoke_shape:after,
#reviews-2:after {
  position: absolute;
  content: '';
  width: 76%;
  height: 100%;
  background-color: #f9f9f9;
  top: 0;
  left: 12%;
  z-index: -1;
  -webkit-border-radius: 12px;
  -moz-border-radius: 12px;
  -o-border-radius: 12px;
  border-radius: 12px;
}

#reviews-1.aliceblue_shape:after {
  background-color: #f2f2fa;
}

#reviews-2:after {
  width: 56%;
  left: 44%;
  -webkit-border-radius: 12px 0 0 12px;
  -moz-border-radius: 12px 0 0 12px;
  -o-border-radius: 12px 0 0 12px;
  border-radius: 12px 0 0 12px;
}

#reviews-2.aliceblue_shape:after {
  background-color: #f2f2fa;
}

/*------------------------------------------*/
/*  TESTIMONIALS CAROUSEL
/*------------------------------------------*/ 

.review-1,
.review-2 { 
  text-align: center; 
  background-color: #fff;
  padding: 45px 30px;
  margin: 0 10px 20px;
  -webkit-box-shadow: 0px 5px 10px 0 rgba(10, 10, 10, .05);
  box-shadow: 0px 5px 10px 0 rgba(10, 10, 10, .05);
}

.review-3 { 
  margin-bottom: 40px;
}

.review-4 { 
  padding: 0 30px;
  margin-bottom: 40px;
}

.review-3-txt,
.review-4-txt { 
  background-color: #fff;
  padding: 35px 25px;
  -webkit-border-radius: 12px;
  -moz-border-radius: 12px;
  -o-border-radius: 12px;
  border-radius: 12px;
  -webkit-box-shadow: 0px 5px 10px 0 rgba(10, 10, 10, .05);
  box-shadow: 0px 5px 10px 0 rgba(10, 10, 10, .05);
  margin-bottom: 25px;
}

.review-4-txt { 
  padding: 40px 30px;
}

.review-3-data,
.review-4-data {
  position: relative;
  padding: 0 15px;
}

/*------------------------------------------*/
/*  Testimonial Message Text
/*------------------------------------------*/

.reviews-section h5.h5-sm {
  margin-bottom: 10px;
}

#reviews-4.reviews-section h5.h5-sm {
  margin-bottom: 2px;
}

.reviews-section h5.h5-xs {
  margin-bottom: 0;
}

.review-3-txt p { 
  font-size: 1.2rem;
  line-height: 1.6;
  margin-bottom: 0;
}

.review-4-txt p { 
  font-size: 1.4rem;
  line-height: 1.6;
  font-weight: 400;
  margin-bottom: 0;
}

.txt-highlight {
  display: inline;
  padding: 5px 7px;
  margin: -3px 3px -3px -5px;
  border-radius: 8px;
  background-image: linear-gradient(-100deg, #ffda0233, #ffda02bf 95%, #ffda021a);
}

.review-3-data p, 
.review-4-data p {
  margin-bottom: 0;
}

/*------------------------------------------*/
/*  Testimonials Rating
/*------------------------------------------*/

.app-rating {
  margin-bottom: 15px;
}

.review-3 .app-rating {
  position: absolute;
  top: 1px;
  right: 12px;
  margin-bottom: 0;
}

.review-4 .app-rating {
  position: absolute;
  top: 10px;
  right: 15px;
  margin-bottom: 0;
}

.review-1 .ico-20 [class*="flaticon-"]:before, 
.review-1 .ico-20 [class*="flaticon-"]:after { font-size: 1.125rem; }

/*------------------------------------------*/
/*  Testimonials Download Button
/*------------------------------------------*/

.reviews-download-btn {
  text-align: center;
  margin-top: 30px;
  margin-bottom: 40px;
}

.reviews-download-btn .store img {
  max-height: 55px;
}

.reviews-download-btn .os-version {
  font-size: 1.125rem;
}

/*------------------------------------------*/
/*  Carousel Dots
/*------------------------------------------*/

.reviews-section button {
  background-color: transparent;
  border: none;
  padding: 0 2px;
}

.owl-theme .owl-dots .owl-dot span {
  margin: 0 7px;
}

.owl-dots button:focus {
  outline: none;
  text-decoration: none;
}




/* ==========================================================================
  13. PRICING
  =========================================================================== */

/*------------------------------------------*/
/*  Price Plan
/*------------------------------------------*/

.pricing-plan {
  padding: 50px 40px 45px;
  border: 1px solid #eee;
  -webkit-border-radius: 8px 8px 0 0;
  -moz-border-radius: 8px 8px 0 0;
  -o-border-radius: 8px 8px 0 0;
  border-radius: 8px 8px 0 0;
}

#pricing-1.bg_whitesmoke .pricing-table,
#pricing-3.bg_whitesmoke .pricing-table {
  border-color: transparent;
  -webkit-box-shadow: 0px 5px 10px 0 rgba(10, 10, 10, .05);
  box-shadow: 0px 5px 10px 0 rgba(10, 10, 10, .05);
}

#pricing-1.bg_whitesmoke .pricing-plan,
#pricing-3.bg_whitesmoke .pricing-plan {
  background-color: #fafafa!important;
}

#pricing-2 .pricing-plan {
  padding: 45px 40px 40px;
  border: 1px solid #ddd;
  -webkit-border-radius: 8px ;
  -moz-border-radius: 8px;
  -o-border-radius: 8px;
  border-radius: 8px;
}

.pricing-plan.highlight,
#pricing-2 .pricing-plan.highlight {
  border: 1px solid transparent;
}

.pricing-plan h5 {
  margin-bottom: 25px;
}

#pricing-2 .pricing-plan h5 {
  margin-bottom: 20px;
}

.pricing-plan span {
  font-family: "Google Sans", "Roboto", sans-serif;
  font-size: 60px;
  line-height: 1;
  font-weight: 500;
  letter-spacing: -0.5px;
}

#pricing-2 .pricing-plan span {
  font-size: 54px;
}

.pricing-plan sup {
  font-family: "Google Sans", "Roboto", sans-serif;
  font-size: 40px;
  font-weight: 500;
  top: -7px;
  right: 1px;
}

#pricing-2 .pricing-plan sup {
  font-size: 35px;
  top: -7px;
  right: 3px;
}

#pricing-2 .pricing-plan sup.validity {
  font-weight: 300;
  font-size: 1.2rem;
  top: 0px;
  left: 0;
}

#pricing-2 .pricing-plan sup.validity span {
  position: relative;
  font-size: 2.75rem;
  left: -2px;
}

.pricing-plan p {
  margin-top: 10px;
  margin-bottom: 5px;
}

.pricing-plan p.p-sm {
  padding: 0 5%;
  margin-top: 0;
  margin-bottom: 0;
}

#pricing-2 .pricing-plan p.p-sm {
  padding: 0;
  margin-top: 15px;
}

.pricing-plan.highlight p.p-sm {
  opacity: .65;
}

#pricing-2 .pricing-plan.highlight p.p-sm {
  opacity: 1;
}

.pricing-trial-link {
  display: block;
  text-decoration: underline;
}

/*------------------------------------------*/
/*  Pricing Hightlight Badge
/*------------------------------------------*/

.badge-wrapper {
  position: absolute;
  top: -17px;
  left: 0;
  width: 100%;
  text-align: center;
}

.highlight-badge {
  display: inline-block;
  margin: 0 auto;
  padding: 8px 45px;
  -webkit-border-radius: 20px;
  -moz-border-radius: 20px;
  -o-border-radius: 20px;
  border-radius: 20px;
}

.highlight-badge h6 {
  line-height: 1;
  margin-bottom: 0;
}

/*------------------------------------------*/
/*  Pricing Table Body
/*------------------------------------------*/

.pricing-description {
  padding: 40px 0 45px;
  border: 1px solid #eee;
  border-top: none;
  -webkit-border-radius: 0 0 8px 8px;
  -moz-border-radius: 0 0 8px 8px;
  -o-border-radius: 0 0 8px 8px;
  border-radius: 0 0 8px 8px;
} 

ul.features li {
  padding: 13px 0;
} 

ul.features li p {
  color: #757575;
  line-height: 1;
  margin-bottom: 0;
} 

/*------------------------------------------*/
/*  Pricing Table Button
/*------------------------------------------*/

.pricing-table .btn {
  margin-top: 25px;
}

#pricing-2 .pricing-table .btn {
  padding: 12px 38px;
}

/*------------------------------------------*/
/*  PRICING NOTICE TEXT
/*------------------------------------------*/

.pricing-notice {
  text-align: center;
  margin-top: 15px;
  margin-bottom: 40px;
}

.pricing-notice p {
  padding: 0 5%;
  margin-bottom: 0;
}

.pricing-notice p span {
  color: #000;
  font-weight: 700;
  margin-right: 5px;
}




/* ==========================================================================
  14. TEAM
  =========================================================================== */

/*------------------------------------------*/
/*    TEAM MEMBER
/*------------------------------------------*/

.team-member {
  text-align: center;
  margin-bottom: 40px;
}

/*------------------------------------------*/
/*    TEAM MEMBER PHOTO
/*------------------------------------------*/

.team-member-photo {
  text-align: center;
  margin-bottom: 25px;
}

.team-member-photo img {
  -webkit-border-radius: 14px;
  -moz-border-radius: 14px;
  -o-border-radius: 14px;
  border-radius: 14px;
}

/*------------------------------------------*/
/*    TEAM MEMBER TYPOGRAPHY
/*------------------------------------------*/

.team-member-data h5 {
  line-height: 1;
  margin-bottom: 3px;
}

.team-member-data p,
.team-member-data span {
  display: block;
  font-size: 1.175rem;
  margin-bottom: 0;
}




/* ==========================================================================
  15. BRANDS
  =========================================================================== */

#brands-1 {
  padding-top: 50px;
  padding-bottom: 50px;
}

.brands-2-wrapper {
  padding: 0 3%;
}

.brands-section h4 {
  line-height: 1.4;
  padding: 0 5%;
  margin-bottom: 50px;
}

#brands-1 p.p-xl {
  font-weight: 400;
  margin-bottom: 35px;
}

/*------------------------------------------*/
/*  BRANDS LOGO HOLDER
/*------------------------------------------*/ 

#brands-1 .brand-logo {
  padding: 0 22px;
  opacity: .7;
  -webkit-transition: all 400ms ease-in-out;
  -moz-transition: all 400ms ease-in-out;
  -o-transition: all 400ms ease-in-out;
  -ms-transition: all 400ms ease-in-out;
  transition: all 400ms ease-in-out;
} 

#brands-2 .brand-logo {
  display: inline-block;
  width: 20%;
  float: left;
  opacity: .8;
  padding: 0 20px;
  margin-bottom: 30px;
  -webkit-transition: all 400ms ease-in-out;
  -moz-transition: all 400ms ease-in-out;
  -o-transition: all 400ms ease-in-out;
  -ms-transition: all 400ms ease-in-out;
  transition: all 400ms ease-in-out;
} 

#brands-1 .brand-logo:hover,
#brands-2 .brand-logo:hover {
  opacity: 1;
} 

#brands-1 .owl-dots {
  display: none;
}




/* ===================================================================================
  16.  MORE APPS
  =================================================================================== */ 

.abox-1-wrapper {
  padding-left: 40px;
  padding-right: 40px;
}

/*------------------------------------------*/
/*  APP BOX
/*------------------------------------------*/ 

.abox-1 {
  position: relative;
  background-color: #fff;
  margin: 0 0 30px;
  padding: 15px 6px;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  border-radius: 3px;
  -webkit-box-shadow: 0px 5px 10px 0 rgba(10, 10, 10, .05);
  box-shadow: 0px 5px 10px 0 rgba(10, 10, 10, .05);
  -webkit-transition: all 400ms ease-in-out;
  -moz-transition: all 400ms ease-in-out;
  -o-transition: all 400ms ease-in-out;
  -ms-transition: all 400ms ease-in-out;
  transition: all 400ms ease-in-out;  
}

.abox-2 {
  position: relative;
  text-align: center;
  margin-bottom: 40px;
}

.abox-1:hover,
.abox-2:hover .abox-2-img {
  box-shadow: 0 0 0 0 rgba(56, 60, 67, .07), 0 8px 15px 0 rgba(56, 60, 67, .1);
}

/*------------------------------------------*/
/*  APP LOGO
/*------------------------------------------*/ 

.abox-2-img {
  position: relative;
  -webkit-border-radius: 6px;
  -moz-border-radius: 6px;
  border-radius: 6px;
  padding: 50px 30px 90px;
  margin-bottom: 35px;
  -webkit-transition: all 400ms ease-in-out;
  -moz-transition: all 400ms ease-in-out;
  -o-transition: all 400ms ease-in-out;
  -ms-transition: all 400ms ease-in-out;
  transition: all 400ms ease-in-out;  
}

.abox-2-img img {
  width: auto;
  max-width: inherit;
  max-height: 120px;
}

/*------------------------------------------*/
/*  APP TEXT
/*------------------------------------------*/ 

.abox-1-txt {
  padding-left: 10px;
}

/*------------------------------------------*/
/*  APP BOX TYPOGRAPHY
/*------------------------------------------*/

.abox-1-txt h5 {
  line-height: 1;
  margin-bottom: 6px;
}

.abox-1-txt p {
  margin-bottom: 2px;
}

.abox-2 h5 {
  line-height: 1;
  margin-bottom: 12px;
}

.abox-2 span.app-cat {
  font-size: 1.125rem;
  line-height: 1;
  display: block;
  margin-bottom: 15px;
}

.app-links p {
  display: inline-block;
  margin-bottom: 0;
}

.app-links a {
  color: #454545;
  margin: 0 8px;
}

.app-links a span {
  padding-right: 2px;
}

.app-links.ico-20 [class*="flaticon-"]:before, 
.app-links.ico-20 [class*="flaticon-"]:after { font-size: 1rem; }

/*------------------------------------------*/
/*  App Version
/*------------------------------------------*/ 

.abox-1 .app-version {
  position: absolute;
  top: 16px;
  right: 16px;
}

.abox-2-img .app-version {
  position: absolute;
  bottom: 25px;
  right: 25px;
}

.abox-1 .app-version span,
.abox-2-img .app-version span {
  background-color: #f2f5f7;
  padding: 3px 8px;
  font-family: 'Poppins', sans-serif;
  color: #998da0;
  font-size: 0.95rem;
  font-weight: 400;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  border-radius: 3px;
}

.abox-2-img .app-version span {
  background-color: transparent;
  border: 1px solid #bbb;
  padding: 2px 7px;
}

/*------------------------------------------*/
/*  App Rating
/*------------------------------------------*/ 

.app-rating {
  color: #998da0;
  font-size: 0.95rem;
} 

.abox-2-img .app-rating {
  position: absolute;
  bottom: 25px;
  left: 25px;
} 

.app-rating span {
  position: relative;
  color: #fdb827;
  top: -1px;
} 

.abox-1-txt .ico-20 [class*="flaticon-"]:before, .abox-2-img .ico-20 [class*="flaticon-"]:before, 
.abox-1-txt .ico-20 [class*="flaticon-"]:after, .abox-2-img .ico-20 [class*="flaticon-"]:after { font-size: 0.9rem; }




/* ==========================================================================
  17. FAQs
  =========================================================================== */

#faqs-1 {
  padding-top: 75px;
  padding-bottom: 100px;
}

.faqs-1-title {
  margin-top: 20px;
}

#faqs-2 .question {
  margin-bottom: 30px;
}

/*------------------------------------------*/
/*  FAQs TYPOGRAPHY
/*------------------------------------------*/

#faqs-2 .question h5 {
  margin-bottom: 15px;
}

/*------------------------------------------*/
/*  FAQS ACCORDION  
/*------------------------------------------*/

#faqs-1 .card-header {
  padding: 25px 0;
  background-color: transparent;
  border: none;
  position: relative;
}

#faqs-1 #accordion [data-toggle="collapse"].collapsed:after,
#faqs-1 #accordion [data-toggle="collapse"]:after {
  font-family: Flaticon;
  font-weight: 300; 
  content: "\f177";
  position: absolute;
  font-size: 0.85rem;
  top: 29px;
  right: 5px;
}

#faqs-1 #accordion [data-toggle="collapse"].collapsed:after {
  content: "\f19a";
}

#faqs-1 .card-header a {
  color: #463853;
}

#faqs-1 .card-header a:hover {
  color: #151515;
}

/*------------------------------------------*/
/*  Question
/*------------------------------------------*/

#accordion .card {
  background-color: transparent;
  border: none;
  border-bottom: 1px solid #d4d4d4;
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
  border-radius: 0; 
}

#accordion .card.last-card {
  border-bottom: none;
}

#accordion .card-header h5 {
  line-height: 1;
  margin-bottom: 0;
}

.question h5 span,
#accordion .card-header h5 span {
  margin-right: 3px;
}

/*------------------------------------------*/
/*  Answer
/*------------------------------------------*/

#accordion .card-body {
  padding: 0 0 15px 0; 
}

/*------------------------------------------*/
/*  MORE QUESTIONS BUTTON
/*------------------------------------------*/ 

.more-questions a {
  text-decoration: underline;
}




/* ==========================================================================
  18. DOWNLOAD
  =========================================================================== */

.downloads-2-wrapper {
  padding: 50px 40px;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  border-radius: 10px;  
  overflow: hidden;
  -webkit-box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  -moz-box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

#download-3 {
  padding-top: 70px;
  padding-bottom: 70px;
}

#download-1 .container,
#download-5 .container {
  overflow: hidden;
}

.d2-txt {
  overflow: hidden;
  padding-left: 25px;
}

/*------------------------------------------*/
/*  DOWNLOAD TYPOGRAPHY
/*------------------------------------------*/ 

.d2-txt h4 {
  padding-top: 10px;
  margin-bottom: 0;
}

.download-3-txt h2 {
  margin-bottom: 30px;
} 

.download-4-txt h3 {
  margin-bottom: 10px;
} 

.download-4-txt h5 {
  font-weight: 300;
  margin-bottom: 30px;
} 

.download-6-txt h2 {
  margin-bottom: 25px;
} 

.download-3-txt p {
  margin-top: 20px;
  margin-bottom: 0;
}

.download-5-txt p {
  padding: 0 15%;
  margin-top: 15px;
  margin-bottom: 30px;
}

.download-6-txt p {
  padding-right: 5%;
  margin-bottom: 35px;
}

/*------------------------------------------*/
/*  DOWNLOAD LOGO
/*------------------------------------------*/

.download-2-logo {     
  float: left;
  text-align: center;
}

.download-2-logo img {
  width: auto;
  max-width: inherit;
  max-height: 100px;
}




/* ==========================================================================
  19. NEWSLETTER
  =========================================================================== */

#newsletter-1 .container {
  padding-left: 0;
  padding-right: 0;
}

.newsletter-wrapper {
  background-color: #fff;
  border: 2px solid #e4e5e9;
  padding: 45px 40px;
  -webkit-border-radius: 12px;
  -moz-border-radius: 12px;
  border-radius: 12px;  
}

.bg_whitesmoke .newsletter-wrapper {
  border-color: transparent;
  -webkit-box-shadow: 0px 5px 10px 0 rgba(10, 10, 10, .05);
  box-shadow: 0px 5px 10px 0 rgba(10, 10, 10, .05);
} 

#newsletter-1 .section-id {
  margin-bottom: 15px;
}

/*------------------------------------------*/
/*  NEWSLETTER TYPOGRAPHY
/*------------------------------------------*/ 

.newsletter-txt h4,
.newsletter-txt h3 {
  margin-bottom: 0;
}

/*------------------------------------------*/
/*  NEWSLETTER FORM
/*------------------------------------------*/ 

.newsletter-section .newsletter-form {
  margin-top: 50px;
}

/*------------------------------------------*/
/*   Newsletter Form Input
/*------------------------------------------*/

.newsletter-section .form-control {
  height: 55px;
  background-color: transparent;
  border: none;
  border-bottom: 2px solid #ddd;
  color: #998da0;
  font-size: 1.25rem;
  font-weight: 400;
  padding: 0 5px;  
  margin-right: 15px;
  box-shadow: none;
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
  -o-border-radius: 0;
  border-radius: 0;
}

.newsletter-section .input-group > .custom-select:not(:last-child), 
.newsletter-section .input-group > .form-control:not(:last-child) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

/*------------------------------------------*/
/*  Newsletter Form Input Focus
/*------------------------------------------*/

.newsletter-section .form-control:focus {
  outline: 0;
  box-shadow: none;
  border-bottom: 2px solid #998da0;
}

/*------------------------------------------*/
/*  Newsletter Form Input Placeholder
/*------------------------------------------*/

.newsletter-section .form-control::-moz-placeholder { color: #ccc; } 
.newsletter-section .form-control:-ms-input-placeholder { color: #ccc; } 
.newsletter-section .form-control::-webkit-input-placeholder { color: #ccc; } 

/*------------------------------------------*/
/*  Newsletter Form Button
/*------------------------------------------*/

.newsletter-section .btn {
  display: block; 
  width: 100%!important;
  height: 55px;
}

/*------------------------------------------*/
/*  Newsletter Form Notification
/*------------------------------------------*/

.newsletter-section .form-notification {
  color: #e03a3e;
  font-size: 0.975rem;
  font-weight: 400;
  line-height: 1;
  margin-top: 15px;
  margin-left: 5px;
}

.newsletter-section .form-notification label.valid,
.newsletter-section .form-notification label.error {
  color: #e03a3e;
}

.newsletter-section .newsletter-form label.valid {
  color: #48af4b;
}




/* ==========================================================================
  20. BLOG
  =========================================================================== */

.blog-post-img {
  -webkit-border-radius: 8px 8px 0 0; 
  -moz-border-radius: 8px 8px 0 0; 
  -o-border-radius: 8px 8px 0 0; 
  border-radius: 8px 8px 0 0; 
  overflow: hidden;
}  

.blog-post-txt {
  position: relative;
  background-color: #fff;
  border: 1px solid #eee;
  -webkit-border-radius: 0 0 8px 8px;
  -moz-border-radius: 0 0 8px 8px;
  -o-border-radius: 0 0 8px 8px;
  border-radius: 0 0 8px 8px;
  padding: 25px 20px 15px;
}

/*------------------------------------------*/
/*  BLOG TYPOGRAPHY
/*------------------------------------------*/

.blog-post-txt h5 {
  line-height: 1.4;
  margin-bottom: 12px;
}

.blog-post-txt a {
  color: #463853;
}

.blog-post-txt a:hover {
  color: #998da0;
}

/*------------------------------------------*/
/*  BLOG POST META
/*------------------------------------------*/

.post-meta {
  margin-top: 30px;
  text-align: right;
  position: relative;
}

.post-author-avatar {
  position: absolute;
  left: 0;
  top: 1px;
}

.post-author-avatar img {
  width: 34px;
  height: 34px;
  display: inline-block;
  margin: 0;
  -webkit-border-radius: 100%;
  -moz-border-radius: 100%;
  -o-border-radius: 100%;
  border-radius: 100%;
}

.post-meta p {
  padding-top: 10px;
  padding-right: 10px;
}




/* ==========================================================================
  21. BLOG LISTING
  =========================================================================== */

.posts-wrapper {
  padding: 0 4%;
}

.featured-post {
  margin: 0 4%;
}

h5.posts-category {
  border-bottom: 1px solid #ddd;
  padding-bottom: 25px;
  margin-top: 20px;
  margin-bottom: 50px;
}

/*------------------------------------------*/
/*  Featured Post Badge
/*------------------------------------------*/ 

.featured-badge {
  z-index: 70;
  position: absolute;
  top: 20px;
  right: 20px;
  width: 45px;
  height: 45px;
  -webkit-border-radius: 100%;
  -moz-border-radius: 100%;
  -o-border-radius: 100%;
  border-radius: 100%;
}

.featured-badge.ico-30 [class*="flaticon-"]:before, 
.featured-badge.ico-30 [class*="flaticon-"]:after { line-height: 42px!important; }

/*------------------------------------------*/
/*  BLOG POST
/*------------------------------------------*/ 

#blog-page .blog-post {
  background-color: #fff;
  margin-bottom: 40px;
  -webkit-border-radius: 8px;
  -moz-border-radius: 8px;
  border-radius: 8px;
  overflow: hidden;
}

#blog-page .blog-post-img {
  -webkit-border-radius: 0; 
  -moz-border-radius:  0; 
  -o-border-radius: 0; 
  border-radius: 0; 
  overflow: hidden;
}  

#blog-page  .wide-post .blog-post-txt {
  padding: 0 50px 0 40px;
  border: none;
}




/* ==========================================================================
  22. SINGLE BLOG POST
  =========================================================================== */

/*------------------------------------------*/
/*  SINGLE POST TITLE
/*-----------------------------------------*/

.single-post-title {
  padding: 0 4%;
  margin-bottom: 35px;
}

#single-post p.post-tag {
  margin-bottom: 30px;
}

#single-post .post-data {
  margin-top: 30px;
  margin-bottom: 35px;
}

/*------------------------------------------*/
/*  SINGLE POST AUTHOR
/*------------------------------------------*/ 

#single-post .post-author-avatar {
  position: relative;
  left: 0;
  top: 0;
}

#single-post .post-author-avatar img {
  width: 60px;
  height: 60px;
  display: inline-block;
  float: left;
  margin: 0 15px 0 0;
  -webkit-border-radius: 100%;
  -moz-border-radius: 100%;
  -o-border-radius: 100%;
  border-radius: 100%;
}

/*------------------------------------------*/
/*  AUTHOR AVATAR
/*------------------------------------------*/

#single-post .post-author {
  position: relative;
  top: 3px;
  left: 3px;
}

#single-post  .post-author span {
  display: block;
  color: #888;
  font-size: 1.0625rem;
  font-weight: 400;
  margin-bottom: 0;
}

/*------------------------------------------*/
/*  FOLLOW BUTTON
/*------------------------------------------*/ 

#single-post .follow-author {
  position: relative;
  color: #999;
  font-size: 1.05rem;
  line-height: 1;
  padding: 3px 8px;
  top: -5px;
  margin-left: 6px;
  border: 1px solid #999;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  border-radius: 4px;
}

/*------------------------------------------*/
/*  SINGLE POST TEXT
/*-----------------------------------------*/

.single-post-txt {
  padding: 0 4%;
}

.single-post-txt h5 {
  margin-top: 25px;
  margin-bottom: 28px;
}

.single-post-txt span.txt-500 {
  color: #463853;
}

/*------------------------------------------*/
/*  BLOG POST INNER IMAGE
/*------------------------------------------*/ 

.post-inner-img {
  text-align: center;
  margin-top: 50px;
  margin-bottom: 50px;  
}

.post-inner-img img {
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  -o-border-radius: 10px;
  border-radius: 10px; 
}

.post-inner-img p {
  margin-top: 25px;
  margin-bottom: 0;  
}

/*------------------------------------------*/
/*  POST TAGS 
/*------------------------------------------*/ 

.post-share-links {
  padding: 0 4%;
  margin-top: 80px;
}

.post-tags-list span a {
  color: #757575;
  font-size: 1.0625rem;
  font-weight: 400;
  line-height: 1.42857;
  background-color: transparent;
  border: 2px solid #998da0;
  padding: 7px 14px;
  margin-right: 4px;
  -webkit-border-radius: 6px;
  -moz-border-radius: 6px;
  border-radius: 6px;
  -webkit-transition: all 450ms ease-in-out;
  -moz-transition: all 450ms ease-in-out;
  -o-transition: all 450ms ease-in-out;
  -ms-transition: all 450ms ease-in-out;
  transition: all 450ms ease-in-out;
}

.post-tags-list span a:hover {
  background-color: #e7e7e7;
  border-color: #e7e7e7;
}

/*------------------------------------------*/
/*  POST SHARE ICONS
/*-----------------------------------------*/

.post-share-links .share-social-icons {
  display: inline-block; 
  padding-left: 0;
}

.post-share-links .share-social-icons li {
  width: auto !important;
  display: inline-block !important;
  vertical-align: top;
  clear: none !important;
  padding: 0;
}

.post-share-links .share-social-icons a.share-ico span {
  margin-right: 15px;
}

/*------------------------------------------*/
/*  OTHER POSTS TYPOGRAPHY
/*------------------------------------------*/ 

.other-posts {
  margin: 90px 4% 0;
  padding: 90px 0 60px;
  border-top: 1px solid #ddd;
  border-bottom: 1px solid #ddd;
}

.other-posts h5 {
  margin-bottom: 12px;
}

.prev-post a, 
.next-post a {
  font-size: 1.125rem;
  line-height: 1.2;
}

.all-posts a { 
  font-size: 1.75rem;
  line-height: 1;
}

.prev-post a:hover, 
.next-post a:hover {
  color: #000;
  text-decoration: underline;
}

.all-posts a:hover { 
  color: #333;
}

/*------------------------------------------*/
/*  POST COMMENTS
/*------------------------------------------*/

.comments-wrapper {
  padding: 0 4%;
}

.post-comments hr {
  margin-top: 24px; 
  margin-bottom: 40px; 
}

.post-comments h5.h5-lg {
  margin-bottom: 80px;
}

.post-comments img {
  width: 70px;
  height: 70px;
  -webkit-border-radius: 100%;
  -moz-border-radius: 100%;
  -o-border-radius: 100%;
  border-radius: 100%;
}

.comment-meta {
  margin-bottom: 10px;
}

.comment-meta h5.h5-sm {
  line-height: 1;
  margin-bottom: 2px;
}

.comment-date {
  font-size: 17px;
  font-weight: 400;
  margin-bottom: 5px;
}

/*------------------------------------------*/
/*  Comment Reply 
/*------------------------------------------*/

.btn-reply {
  margin-left: 3px;
}

.btn-reply a {
  font-size: 17px;
  font-weight: 400; 
}

.btn-reply span {
  position: relative;
  top: 3px;
  margin-right: 4px;
  left: 3px;
}

.btn-reply.ico-20 [class*="flaticon-"]:before, 
.btn-reply.ico-20 [class*="flaticon-"]:after { font-size: 0.975rem; }

.btn-reply a:hover {
  color: #222;
}

/*------------------------------------------*/
/*  SINGLE POST COMMENT FORM
/*------------------------------------------*/

.post-comments #leave-comment h5 {
  padding-bottom: 0;
  margin-top: 80px;
  margin-bottom: 5px;
  border: none;
}

.comment-form {
  position: relative;
  margin-top: 70px;
}

.comment-form p {
  color: #333;
  font-size: 1.1rem;
  line-height: 1;
  font-weight: 700;
  margin-bottom: 10px;
  padding-left: 8px;
  display: block;
}

.comment-form .form-control {
  height: 60px;
  background-color: #fff;
  border: 1px solid #ccc;
  color: #333;
  font-size: 1.1875rem;
  font-weight: 300;
  padding: 0 15px;
  margin-bottom: 22px;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  border-radius: 4px;
  -webkit-transition: all 400ms ease-in-out;
  -moz-transition: all 400ms ease-in-out;
  -o-transition: all 400ms ease-in-out;
  -ms-transition: all 400ms ease-in-out;
  transition: all 400ms ease-in-out;
}

.comment-form .form-control:focus {
  border-color: #00b2e4;
  outline: 0px none;
  box-shadow: none;
}

.comment-form textarea { min-height: 200px; }
.comment-form textarea.form-control { 
  padding: 20px;
}

/*------------------------------------------*/
/*  Comment Form Button
/*------------------------------------------*/

.comment-form .btn {
  margin-top: 15px;
}

.comment-form .btn:focus {
  outline: 0px none;
  box-shadow: none;
}

/*------------------------------------------*/
/*  Comment Form Message
/*------------------------------------------*/

.comment-form-msg {
  width: 100%!important;
  display: block;
  margin-top: 10px;
  padding-left: 0;
}

.comment-form .loading {
  color: #00b2e4;
  font-size: 18px;
  font-weight: 400;
}

.comment-form .error {
  color: #e74c3c;
  font-size: 14.5px;
  font-weight: 300;
  margin-bottom: 20px;
}

.comment-form .form-control.error {
  font-size: 17px!important;
}




/* ==========================================================================
  23. CONTACTS
  =========================================================================== */

#contacts-1 {
  padding-top: 60px;
  padding-bottom: 60px;
} 

/*------------------------------------------*/
/*  CONTACTS TYPOGRAPHY
/*------------------------------------------*/

.contacts-1-txt h4 {
  line-height: 1.4;
  margin-bottom: 25px;
}

#contacts-2 .contact-form p {
  color: #333;
  font-family: "Google Sans", "Roboto", sans-serif;
  line-height: 1;
  font-weight: 500;
  margin-bottom: 5px;
  padding-left: 10px;
}

#contacts-2 .contact-form span {
  display: block;
  color: #aaa;
  font-size: 17px;
  line-height: 1;
  font-weight: 300;
  margin-bottom: 20px;
  padding-left: 10px;
}

/*------------------------------------------*/
/*  CONTACT FORM INPUT
/*------------------------------------------*/

.contact-form .form-control {
  height: 60px;
  background-color: #fff;
  border: 1px solid #ccc;
  box-shadow: 0 0 0 0;
  color: #998da0;
  font-size: 1.1rem;
  font-weight: 300;
  padding: 0 15px;
  margin-bottom: 30px;
  -webkit-border-radius: 6px;
  -moz-border-radius: 6px;
  border-radius: 6px;
}

.contact-form .custom-select {
  display: inline-block;
  width: 100%;
  height: 60px;
  line-height: 1;
  color: #998da0;
  border: 1px solid #ccc;
  font-size: 1.125rem;
  font-weight: 300;
  padding: 0 15px;
  margin-bottom: 30px;
  box-shadow: 0;
  -webkit-border-radius: 6px;
  -moz-border-radius: 6px;
  border-radius: 6px;
}

/*------------------------------------------*/
/*  Contact Form Textarea
/*------------------------------------------*/

.contact-form textarea { min-height: 220px; }
.contact-form textarea.form-control { 
  padding: 20px 15px;
}

/*------------------------------------------*/
/*  Contact Form Placeholder
/*------------------------------------------*/

.contact-form .form-control::-moz-placeholder { color: #998da0; } 
.contact-form .form-control:-ms-input-placeholder { color: #998da0; }
.contact-form .form-control::-webkit-input-placeholder { color: #998da0;; }

/*------------------------------------------*/
/*  Contact Form Input Focus
/*------------------------------------------*/

.contact-form .form-control:focus,
.contact-form .custom-select:focus {
  outline: 0px none;
  box-shadow: none;
  border-color: #009ddc;
}

/*------------------------------------------*/
/*    Contact Form Message
/*------------------------------------------*/

.contact-form-msg {
  width: 100%!important;
  display: block;
  margin-top: 15px;
  padding-left: 0;
}

.contact-form .loading {
  color: #00b2e4;
  font-size: 18px;
  font-weight: 500;
}

.contact-form  .error {
  color: #e74c3c;
  font-size: 14px;
  font-weight: 300;
  margin-bottom: 20px;
}




/* ==========================================================================
  24. FOOTER
  ========================================================================== */ 

.footer {
  padding-top: 80px;
  padding-bottom: 30px;
}

#footer-1 .footer-links,
#footer-2 .footer-links,
#footer-3 .footer-links {
  margin-bottom: 40px;
  padding-left: 10%;
}

/*------------------------------------------*/
/*  FOOTER LOGO
/*------------------------------------------*/

img.footer-logo {
  width: auto;
  max-width: inherit;
  max-height: 40px;
}

/*------------------------------------------*/
/*  FOOTER TYPOGRAPHY
/*------------------------------------------*/

.footer h6,
.footer h5.h5-xs,
.footer h5.h5-sm,
.footer h5.h5-md {
  line-height: 1;
  margin-top: 3px;
  margin-bottom: 25px;
}

.footer p,
.footer a {
  color: #998da0;
  transition: all 0.2s ease-in-out;
}

.footer a span {
  -webkit-transition: all 400ms ease-in-out;
  -moz-transition: all 400ms ease-in-out;
  -o-transition: all 400ms ease-in-out;
  -ms-transition: all 400ms ease-in-out;
  transition: all 400ms ease-in-out;
}

.footer .footer-links a:hover {
  color: #151515;
  padding-left: 5px;
}

.footer .footer-socials-links h5 {
  font-weight: 700;
  margin-bottom: 17px;
}

/*------------------------------------------*/
/*  FOOTER LINKS
/*------------------------------------------*/

.footer-links li {
  width: auto !important;
  display: block !important;
  vertical-align: top;
  clear: none !important;
  margin: 0;
  padding: 0;
}

.footer-links li p {
  margin-bottom: 8px;
}

#footer-3 .footer-links li p {
  margin-bottom: 10px;
}

.footer-list span {
  position: relative;
  font-weight: 300;
  top: 2px;
  margin-right: 5px;
}

.footer-list.ico-20 [class*="flaticon-"]:before, 
.footer-list.ico-20 [class*="flaticon-"]:after { font-size: 1.1rem; }

/*------------------------------------------*/
/*  FOOTER SOCIAL LINKS
/*------------------------------------------*/

#footer-1 .foo-facebook:hover { color: #3b5998; }
#footer-1 .foo-twitter:hover { color: #00a9ed; }
#footer-1 .foo-instagram:hover { color: #dc3c59; }
#footer-1 .foo-dribbble:hover { color: #d92d84; }
#footer-1 .foo-behance:hover { color: #2473f6; }
#footer-1 .foo-pinterest:hover { color: #ac281a; }
#footer-1 .foo-linkedin:hover { color: #015886; }
#footer-1 .foo-google-plus:hover { color: #cd1111; }
#footer-1 .foo-youtube:hover { color: #cd1b20; }
#footer-1 .foo-tumblr:hover { color: #3a5976; }
#footer-1 .foo-vk:hover { color: #3b5998; }
#footer-1 .foo-yahoo:hover { color: #7b0099; }
#footer-1 .foo-yelp:hover { color: #d32323; }

/*------------------------------------------*/
/*  FOOTER NEWSLETTER FORM
/*------------------------------------------*/

.footer-form .form-control {
  height: 48px;
  background-color: #fff;
  border: 1px solid #ccc;
  border-right: none;
  color: #463853;
  font-size: 1.15625rem;
  font-weight: 300;
  padding: 0 15px;
  -webkit-border-radius: 4px 0 0 4px;
  -moz-border-radius: 4px 0 0 4px;
  border-radius: 4px 0 0 4px;
  -webkit-transition: all 400ms ease-in-out;
  -moz-transition: all 400ms ease-in-out;
  -o-transition: all 400ms ease-in-out;
  -ms-transition: all 400ms ease-in-out;
  transition: all 400ms ease-in-out;
}

.bg_whitesmoke .footer-form .form-control {
  background-color: #fafafa;
  border: 1px solid #ccc;
  border-right: none;  
  color: #f4f4f4;
}

.bg_dark .footer-form .form-control {
  background-color: #222;
  border: 1px solid #444;
  border-right: none;  
  color: #ccc;
}

.footer-form .btn span { 
  position: relative; 
  top: 2px;
}

.footer-form .ico-25 [class*="flaticon-"]:before, 
.footer-form .ico-25 [class*="flaticon-"]:after { font-size: 1.35rem; }


/*------------------------------------------*/
/*  Newsletter Form Input Focus
/*------------------------------------------*/

.footer-form .form-control:focus {
  border: 1px solid #ccc;
  border-right: none;
  outline: 0;
  box-shadow: none;
}

/*------------------------------------------*/
/*  Newsletter Form Button
/*------------------------------------------*/

.footer-form .btn {
  height: 48px;
  color: #aaa;
  background-color: #fff;
  border: 1px solid #ccc;
  border-left: none;
  font-size: 1.45rem;
  line-height: 1!important;
  margin-top: 0;
  padding: 0 15px;
  -webkit-border-radius: 0 4px 4px 0;
  -moz-border-radius: 0 4px 4px 0;
  border-radius: 0 4px 4px 0;
}

.footer-form .btn span {
  -webkit-transition: all 400ms ease-in-out;
  -moz-transition: all 400ms ease-in-out;
  -o-transition: all 400ms ease-in-out;
  -ms-transition: all 400ms ease-in-out;
  transition: all 400ms ease-in-out;
}

.footer-form .btn:hover span {
  color: #998da0;
}

/*------------------------------------------*/
/*  Footer Form Notification
/*------------------------------------------*/

.footer-form .form-notification {
  color: #e03a3e;
  font-size: 0.95rem;
  line-height: 1.25;
  font-weight: 400;
  margin-top: 12px;
  margin-left: 5px;
}

/*------------------------------------------*/
/*  BOTTOM FOOTER
/*------------------------------------------*/

.bottom-footer {
  border-top: 1px solid #ddd;
  margin-top: 20px;
  padding-top: 30px;
}

/*------------------------------------------*/
/*  BOTTOM FOOTER LINKS
/*------------------------------------------*/

.bottom-footer-list li {
  display: inline-block !important;
  margin-bottom: 0;
}

.footer .bottom-footer-list li a {
  font-size: 1.0625rem;
  line-height: 1; 
  font-weight: 300;
  margin-left: 10px;
  margin-bottom: 0;
}

.bottom-footer-list li:after { 
  content: "|"; 
  padding-left: 11px; 
  position: relative;
  top: -1px;
}

.bottom-footer-list li.last-li:after { 
  content: " "; 
  padding-left: 0; 
}

/*------------------------------------------*/
/*  FOOTER COPYRIGHT
/*------------------------------------------*/

.footer-copyright p {
  margin-bottom: 0;
}




/* ==========================================================================
  25 INNER PAGE HERO
  =========================================================================== */  

.page-hero-section {
  width: 100%;
  background-repeat: no-repeat;
  background-position: center center;   
  background-size: cover;
  padding-top: 160px;
  padding-bottom: 120px;
}

/*------------------------------------------*/
/*  INNER PAGE HERO TYPOGRAPHY
/*------------------------------------------*/

.page-hero-section h2 {
  margin-bottom: 15px;
}

.page-hero-section p {
  font-size: 1.35rem;
  padding: 0 5%;
  margin-bottom: 0;
}




/* ==========================================================================
  26. INNER PAGE WRAPPER
  =========================================================================== */

.inner-page-wrapper {
  margin-top: 88px;
}

.hero-offset-nav {
  position: relative;
  z-index: 3;
  padding-top: 180px;
}




/* ==========================================================================
  27. TERMS PAGE
  =========================================================================== */

.terms-title {
  padding-bottom: 60px;
  border-bottom: 1px solid #ddd;
  margin-bottom: 60px;
}

.terms-title p {
  line-height: 1;
  margin-top: 25px;
  margin-bottom: 0;
}

.terms-section span.txt-500,
.terms-section span.txt-700 {
  color: #151515;
}

/*------------------------------------------*/
/*  TERMS PAGE TYPOGRAPHY
/*------------------------------------------*/

.terms-box { 
  margin-bottom: 30px;
}

.terms-box h4 {
  margin-bottom: 25px;
}

.terms-box a {
  font-weight: 500;
}




/* ==========================================================================
  28. PAGE PAGINATION
  =========================================================================== */

.page-link {
  color: #998da0;
  font-size: 1.3rem;
  font-weight: 500;
  padding: 7px 15px;
  margin: 0 5px;
  background-color: transparent;
  border: 2px solid transparent;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  -o-border-radius: 3px;
  border-radius: 3px;
}

.pagination.ico-20 [class*="flaticon-"]:before, 
.pagination.ico-20 [class*="flaticon-"]:after { font-size: 0.85rem; } 

.page-item:first-child .page-link {
  border-top-left-radius: 3px;
  border-bottom-left-radius: 3px;
}

.page-item:last-child .page-link {
  border-top-right-radius: 3px;
  border-bottom-right-radius: 3px;
}

.page-item.disabled .page-link {
  color: #998da0;
  background-color: transparent;
  border: 2px solid transparent;
}

.page-item.active .page-link {
  color: #998da0;
  background-color: transparent;
  border: 2px solid #998da0;
}

.page-link:hover {
  color: #998da0;
  background-color: #e7e7e7;
  border: 2px solid #e7e7e7;
  -webkit-box-shadow: 0 5px 10px rgba(0, 0, 0, .05);
  -moz-box-shadow: 0 5px 10px rgba(0, 0, 0, .05);
  box-shadow: 0 5px 10px rgba(0, 0, 0, .05);
}

.page-link:focus {
  background-color: transparent;
  color: #998da0;
  border: 2px solid transparent;
  box-shadow: 0 0;
}




/* ==========================================================================
  29. SCROLL TO TOP
  ========================================================================== */  
 
#scrollUp {
  display: none;
  width: 44px;
  height: 44px;
  position: fixed;
  bottom: 30px;
  right: 25px;
  -webkit-border-radius: 100%;
  -moz-border-radius: 100%;
  border-radius: 100%;
  background-image: url(./images/back-to-top.png);
  background-repeat: no-repeat;
  background-position: 50% 48%;
  background-color: rgba(10, 10, 10, .25);
  -webkit-transition: all 400ms ease-in-out;
  -moz-transition: all 400ms ease-in-out;
  -o-transition: all 400ms ease-in-out;
  -ms-transition: all 400ms ease-in-out;
  transition: all 400ms ease-in-out; 
}

#scrollUp:hover {
  background-color: rgba(0, 0, 0, .4);
}

nav a#pull {  
  display: none;  
}