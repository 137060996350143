.client-photos-container {
display: flex;
flex-wrap: wrap;
justify-content: center;
gap: 10px;
/* padding: 10px; */
}

.client-photos-container > li {
    flex-basis: 350px;
    position: relative;
    cursor: pointer;
}

.client-photo-card {
    border: 3px solid #000;
    box-shadow: 3px 3px 8px 0px rgba(0, 0, 0, 0.3);
    object-fit: cover;
    max-width: 100%;
    vertical-align: middle;
    border-radius: 5px;
    /* max-width: 30vw; */
}

.overlay {
    position: absolute;
    width: 100%;
    height: 100%;
    background: rgba(57, 57, 57, 0.502);
    top: 0;
    left: 0;
    transform: scale(0);
    transition: all 0.2s 0.1s ease-in-out;
    color: #fff;
    border-radius: 5px;
    /* center overlay text */
    display: flex;
    align-items: center;
    justify-content: center;
}