#renter-view-bookable-item-container{
    display: flex;
    flex-direction: row;
    /* flex-wrap: wrap; */
    justify-content: space-between;
    margin-left: 5%;
    margin-right: 5%;
}
#renter-review-checkout{
    position: sticky;
    top: 20px;
}

#renter-view-bookable-item-left{
    width: 60%;
}

#calendar-separator{
    margin-top: 16px;
}

#renter-view-bookable-item-right{
    width: 30%;
    padding-top: 2rem;
}
.checkout-details-rate{
    display: flex;
    justify-content: flex-start;
}
.checkout-details{
    display: flex;
    justify-content: space-between; }
.checkout-details p {
    font-family: Arial, Helvetica, sans-serif;
}
.checkout-details.discounts-container{
    flex-direction: column;
    background-color: rgba(128, 128, 128, 0.274);
    padding-left: 10px;
    padding-top: 10px;
    border-radius: 10px;
    width: 70%;
}
.checkout-details.discounts-container:hover{
    cursor: pointer;
}
/* .checkout-details.discounts-container p{
    font-size: .9rem;
    margin-bottom: .1rem !important;
} */

.price-divider{
    margin-bottom: 8px;
    background-color: rgba(165, 160, 160, 0.871);
    border-radius: 1px;
    height: 2px;
}

.add-on-details{
    color: #0073ff;
    font-size: .9rem;
    margin-left: .9rem;
    border: 1px solid rgba(0, 0, 0, 0.531);
    border-radius: 6px;
    padding: 0 6px;
}
.add-on-description{
    display: flex;
    align-items: center;
}

#rvbi-header{
    text-align: center;
}

@media (max-width: 1200px) {
    #renter-view-bookable-item-container{
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
    #renter-view-bookable-item-left{
        width: 100%;
    }
    #renter-view-bookable-item-right{
        width: 100%;
        padding-top: 2rem;
    }
}