.ClientDetailPageTitle{
    text-align: center;
    
}


.ClientDetailPageCompanyName{
    display: inline-block; 
    font-size: 30px;
    font-variant: small-caps;
    line-height: 1em;
    text-align: center;
    

}

.ClientDetailInfoTitle{
    font-size: 30px;
    text-decoration: underline;
    margin-bottom: 0.5em;
    text-align: center;
}

.ClientDetailContactInfo{
    display: inline; 
    font-size: 24px;
   word-spacing: 2px;
    line-height: 1.5em;
   
  
}

.ClientDetailPageContactInfo{
    display: inline;
    align-items: center;
  
}


.ClientDetailItemInfo{
    display: inline;
    align-items: center;
    font-size: 24px;


}

.ClientDetailContainer{
    align-items: center;
    margin-top: 20px;

} 

.ClientDetailInfo{
    display: inline; 
    font-size: 24px;
   word-spacing: 2px;
    line-height: 1.5em;
    margin: auto;
}


.returnToHome{
    display: flex;
    flex-direction: column;
    width: 200px;
    align-items: center;
    text-align: center;
    border: 2px solid black;
    padding-bottom: 15px;
    letter-spacing: 2px;
    font-size: large;
    
}

.returnToHome:hover{
    display: flex;
    flex-direction: column;
    width: 200px;
    align-items: center;
    text-align: center;
    border: 2px solid black;
    padding-bottom: 15px;
    letter-spacing: 2px;
    font-size: large;
    background-color: rgb(158, 216, 189);
}



/* Carousel */
/* .slider{
    position: relative;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
}

.image{
    width: 1000px;
    height: 600px;
    border-radius: 10px;
}

.right-arrow{
    position: absolute;
    top: 50%;
    right: 32px;
    font-size: 3rem;
    color: black;
}

.left-arrow{
    position: absolute;
    top: 50%;
    left: 32px;
    font-size: 3rem;
    color: black;
}

.slide{
    opacity: 0;
    transition-duration: 1s ease;
}

.slide-active{
    opacity: 1;
    transition-duration: 1s;
    transform: scale(1.08);
} */
