.booked-item-detail-container{
    margin: 3rem;
    justify-content: center;
}


.manage-booking-container{
    margin-top: 4rem;
    margin-left: 4rem;
    display: flex;
    flex-direction: column;
    width: 20vw;
}

.booked-item-financial-info-container{
    display: flex;
    flex-direction: column;
    margin-bottom: 45px;
}

.booked-item-financial-info-container > div{
    display: flex;
    flex-direction: row;
    justify-content: space-between; }
.booking-item-container h2 {
    margin-top: 3rem;
}
.booked-item-container h1 {
    text-align: center;
}
.booking-item-container{
    display: flex;
}

.manage-booking-container > button{
    margin: .3rem;
}

.requires-action{
    background-color: rgba(255, 174, 62, 0.47);
    font-weight: 900;
}

.edit-booking-inputs{
    display: flex;
    flex-direction: column;
}

.edit-booking-input-field{
    margin: 4px !important;
}

/* Add Charge Module */
.booking-detail-modal-background{
    width: 100vw;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.352);
    position: fixed;
    top: 0;
    left: 0;
}
.booking-detail-modal{
    width: 50vw;
    /* height: 50vh; */
    background-color: rgb(255, 255, 255);
    position: fixed;
    top: 50vh;
    left: 50vw;
    transform: translate(-50%, -50%);
    border-radius: 5px; }
@media only screen and (max-width: 1000px){
    .booking-detail-modal{
        width: 95vw;
        /* height: 60vh; */
    }
}
#new-charge-form{
    display: flex;
    flex-direction: column;
    margin: 30px;
    /* width: 50vw;
    height: 50vh;
    background-color: rgb(167, 167, 167);
    position: relative;
    top: 50vh;
    left: 50vw;
    transform: translate(-50%, -50%); */
}

.ledger-table{
    width: 85vw;
    align-self: center;
    margin: auto;
    /* border-top: 2px solid black; */
    /* padding-top: 20px; */
}

.modal-content{
    margin: auto;
    width: 60%;
    border: none !important;
}

.add-charge-modal div{
    margin: 5px 0;
}
.payment-card{
    margin-top: 15px;
    padding: 15px;
    border: 1px solid rgba(0, 0, 0, 0.355);
    border-radius: 15px;
}

.booked-item-summary{
    display: flex;
    display: flex;
    justify-content: center;
}