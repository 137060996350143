.clientBookableItem {
    display : flex;
    flex-wrap: wrap;
}

img{
    border-radius: 8px;
    width: auto;
}

.clientItemTitle{
    display: inline-block;
    flex-direction: row;
    font-size: 20px;
    /* line-height: 1px; */
    margin-top:0;
    font-variant: small-caps;
    line-height: 2px;

}
.clientItemHeadingTitle{
    font-variant: small-caps;
    font-size: larger;
    line-height: 1em;

}
.clientItemDescription{
    display: inline-block; 
    font-size: 20px;
    font-variant: small-caps;
    line-height: 1em;

}
.clientListGridContainer{
    
    margin: 10px;
    border: 2px solid black;
    margin-right: 20px;
    padding: 20px;
    padding-left: 20px;

}

.imageContainer{
    display: flex;
    align-items: center;
    margin: auto;
    border: 2px solid black;
}

.BookableItemsTitle{
    text-align: center;
}

.clientListGridItem:hover{
    background-color: rgb(179, 199, 218);
    font-size: 20px;
    border: 5px black solid;
}

.clientItemTextBox{
    margin-left: 40px;
    align-items: center;
}




.css-11lq3yg-MuiGrid-root{
    padding: 0;
}